@import '../../general_style/var';
@import '../../assets/sass/mixins.scss';
@import '../../assets/sass/variables.scss';

.joinServiceWrapper {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 10px;
  .userJoinDropdown {
    height: 200px;
    width: 200px;
    padding: 10px;
    font-family: var(--primaryFamily);
    font-weight: $fontBold;
    border-radius: $btnRadius;
    border: 1px solid $secondaryColor;
    background-color: white;
    color: $secondaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
    text-decoration: none !important;
    cursor: pointer !important;

    // &:hover {
    //   background-color: $secondaryColor;
    //   color: white !important;
    //   transition: 0.3s ease-in;
    // }
    b {
      display: flex;
      align-items: center;
      gap: 7px;
      font-size: 20px;
      text-align: center;
    }
    img {
      height: 40px;
    }
  }
}
