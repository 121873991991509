@import '../../general_style/var';

.table-slots-datepicker {
  .antd-picker-custom {
    border-bottom: 1px solid #e2e2e2;
    .ant-picker {
      background: transparent;
      border: none !important;
      .ant-picker-input {
        input::placeholder {
          color: #6a6fbf;
        }
        .ant-picker-suffix .anticon {
          color: #6a6fbf !important;
        }
      }
    }
  }

  .allSlots {
    margin: 10px !important;
    .buttonTab {
      min-height: 31px;
      padding: 0 10px;
      border-radius: 10px;
      background-color: #f8f6f4;
      border: 1px solid #d4cfcc;
      cursor: pointer;
      margin-top: 10px !important;
      width: 100%;
    }
    .buttonTab.active {
      background-color: $orange-color;
      color: white;
      border: 1px solid $orange-color;
    }
  }
}
.waitlistPopupButton {
  width: 78%;
  margin: 0 auto;
  display: flex;
  padding: 0 6rem;
  justify-content: end;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 15px;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: #6a6fbf;
    cursor: pointer;
  }
}
.time {
  h3 {
    margin-top: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}
.input_fields {
  .dates {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}
