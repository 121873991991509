@import '../../../assets/sass/variables';
@import '../../../assets/sass/mixins';

// .cropImageModal {
//   //   width: auto !important;
//   max-width: fit-content;
//   .heading-editor {
//     margin-top: 10px;
//     width: fit-content;
//   }
//   .imageCropperContent {
//     // display: flex;
//     // align-items: start;
//     // justify-content: space-between;
//     // gap: 50px;
//     .image-editor-controls {
//       //   margin-top: 22px;
//       //   display: flex;
//       //   flex-direction: column;

//       .control {
//         display: flex;
//         align-items: center;
//         gap: 15px;
//         .image-editor-button {
//           background-color: transparent;
//           border: none;
//           span {
//             font-size: 20px;
//           }
//         }
//       }
//     }
//   }
// }
.cropImageModal {
  width: fit-content !important;
  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }
}
.image-editor-modal-content-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  //   width: 700px;
  max-width: 500px;
}
.image-editor-modal-content-container .image-save-button-in-editor {
  background-color: #002fa7;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  width: 90px;
  border-radius: 7px;
  color: white;
  font-family: 'poppins';
  margin-inline: auto;
}
.image-editor-modal-content-container .heading-editor {
  font-size: 17px;
  font-weight: 600;
}
.image-editor-controls {
  position: absolute;
  bottom: 142px;
  left: 79px;
  display: flex;
  gap: 10px;
}
.image-editor-controls .image-editor-button {
  font-size: 14px;
  color: white;
  background-color: $secondaryColor;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-editor-controls .image-editor-button .anticon {
  margin: 0;
  padding: 0;
}
.imageEditActions {
  display: flex;
  justify-content: center;
  width: 100%;
}
