@import '../../../assets//sass/variables';
@import '../../../assets//sass/mixins';

.primary-btn-green {
  @include btnGreenStyle;
  &:disabled {
    // color: #6a6fbf;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
