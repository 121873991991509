@import '../../general_style/var';

.psychCategoriesWrapper {
  margin: 70px auto;
  width: 90%;
  h2.heading {
    font-family: $madimi-one;
    font-size: 36px;
    font-weight: 300;
    line-height: 47.52px;
    text-align: center;
    margin-bottom: 20px;
  }
  .customFaqsCollapse {
    .ant-collapse-item {
      border-bottom: 4px solid #ff922c;
      //   border-bottom: 1px solid rgba(51, 29, 44, 0.1);
      border-radius: 0 !important;
    }
    .ant-collapse-header {
      flex-direction: row-reverse;
      align-items: center;
      padding: 10px 0;
      .ant-collapse-header-text {
        font-weight: bold;
        font-family: $Hind-Siliguri;
        font-size: 22px;
        font-weight: 400;
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0 0 10px 0;
        .categoriesCollapseContent {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 50px;
            height: auto;
          }
          p {
            font-family: $Hind-Siliguri;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }
    }
  }
}
