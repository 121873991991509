@import '../../general_style/var';

.psychFindProviderWrapper {
  width: 100%;
  margin-top: 80px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
  .psychContainer {
    .findProviderCenterBG {
      position: absolute;
      z-index: -1;
      top: 45px;
      left: -64px;
      overflow: hidden;

      @media screen and (max-width: 540px) {
        position: absolute;
        z-index: -1;
        top: -72px;
        left: -40px;
        transform: rotate(60deg);
        overflow: hidden;
        img {
          overflow: hidden;
        }
      }
    }
    .findProviderCard {
      height: 335px;
      width: 100%;
      border-radius: 30px;
      padding: 20px;
      .topBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        img {
          height: 65px;
          width: auto;
        }
      }

      .content {
        p {
          font-family: $madimi-one;
          font-size: 32px;
          font-weight: 400;
          line-height: 42.24px;
          text-align: left;
          color: #fff;
          @media screen and (max-width: 768px) {
            font-size: 28px;
          }
        }
      }
      .bottomBar {
        display: flex;
        justify-content: end;
        button {
          margin-top: 20px;
          border: 1px solid #56c6cf;
          border-radius: 34px;
          background-color: #fff;
          color: #56c6cf;
          font-family: Hind Siliguri;
          font-size: 18px;
          font-weight: 700;
          line-height: 29.11px;
          text-align: center;
          padding: 10px 35px;
          cursor: pointer;
        }
      }
    }
    .findProviderCard.leftCard {
      background-color: #56c6cf;
    }
    .findProviderCard.rightCard {
      background-color: #6a6fbf;
      margin-top: 125px;
    }
    h1 {
      color: map-get($colors, sky-blue);
      font-size: 32px;
      font-weight: 400;
      font-family: $madimi-one;
      margin-top: 50px;
      @media screen and (max-width: 768px) {
        font-size: 31px;
      }
    }
    .packages-main-contaainer {
      margin-top: 40px;
      p {
        font-family: $Hind-Siliguri;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      .flexWrapper {
        display: flex;
        align-items: center;
        gap: 18px;
        margin-bottom: 15px;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          justify-content: space-between;
          gap: 0;
        }
        h2 {
          color: map-get($colors, black-bg);
          font-family: $madimi-one;
          font-size: 30px;
          font-weight: 400;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        font-family: $Hind-Siliguri;
        color: map-get($colors, simple-text-color);
        margin-bottom: 25px;
        padding-right: 90px;
        @media screen and (max-width: 768px) {
          padding-right: 0px;
          text-align: justify;
        }
      }
    }
  }
  .customEmergencyText {
    font-family: $Hind-Siliguri;
    font-size: 24px;
    font-weight: 400;
    // line-height: 24px;
    text-align: center;
    color: #4b5563;
    margin: 40px;
    span {
      color: #ff922c;
    }
  }
  .imgWrapper {
    width: 100%;
    svg {
      width: 100%;
      height: 600px;
    }
    @media screen and (max-width: 768px) {
      .imgWrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        svg {
          width: 100%;
        }
      }
    }
  }
}
