@import '../../assets/sass/variables';

.helpCenterWrapper {
  width: 90%;
  margin: 30px auto;
  padding-bottom: 20px;
  .helpCenterWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;

    .buttonContainer {
      width: 300px;
      height: 60px;
      // border-radius: 39px;
      background: transparent;
      overflow: hidden;
      display: flex;
      // border: 1px solid $greenColor;
      overflow: hidden;
      margin-bottom: 20px;

      button.active {
        background-color: $greenColor;
        color: #fff;
        border: none;
      }
      button {
        border: none;
        width: 50%;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid $greenColor;
        background-color: #fff;
        cursor: pointer;
        &:first-child {
          border-bottom-left-radius: 40px;
          border-top-left-radius: 40px;
        }
        &:last-child {
          border-bottom-right-radius: 40px;
          border-top-right-radius: 40px;
        }
      }
    }

    h2 {
      font-size: 40px;
      margin-bottom: 20px;
      @media screen and (max-width: 580px) {
        font-size: 25px;
      }
    }
  }
  .customFaqsCollapse {
    .ant-collapse-item {
      border-bottom: 1px solid rgba(51, 29, 44, 0.1);
      border-radius: 0 !important;
    }
    .ant-collapse-header {
      flex-direction: row-reverse;
      padding: 10px 0;
      .ant-collapse-header-text {
        font-weight: bold;
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0 0 10px 0;
        p {
          color: rgba(130, 130, 130, 1);
        }
      }
    }
  }
}
