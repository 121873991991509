@import '../../../general_style/var';

.psychTopProviderCardsWrapper {
  .slider-card-sm {
    padding: 10px 15px;
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    .card-main-coontainer {
      display: flex;
      border-bottom: 1px solid map-get($colors, border-color-03);
      padding-bottom: 17px;
      svg {
        width: 60px;
        height: 60px;
        margin: 10px;
      }
      img {
        width: 60px;
        height: 60px;
        margin: 10px;
        border-radius: 50%;
        object-fit: cover;
      }
      .slide-content {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .slide-title-text {
          font-size: 18px;
          font-weight: 700;
          line-height: 27.38px;
          color: map-get($colors, black);
          font-family: $madimi-one;
        }
        .slide-text-heading {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: map-get($colors, simple-text-color);
        }
        .icon-experience {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-top: 8px;
          svg {
            height: 18px;
            width: auto;
            margin: 0 !important;
          }
          .experience-title {
            color: map-get($colors, green-txt);
            font-weight: 600;
          }
        }
      }
    }
    .card-btn-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-detail-btn {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 700;
        color: map-get($colors, simple-text-color);
        padding: 5px 20px;
        cursor: pointer;
      }
      .card-show-button {
        display: flex;
        align-items: center;
        justify-items: center;
        background: none;
        border: none;
        font-size: 14px;
        line-height: 21.29px;
        font-weight: 700;
        color: map-get($colors, button-blue);
        cursor: pointer;
        .l-svg {
          width: 25px;
          margin-left: 7px;
        }
      }
    }
  }
}
