.mainPolicyWrap {
    width: 90%;
    margin: 20px auto;
  
    .policyHead {
      // h2 {
      //   font-size: 48px;
      //   font-weight: 600;
      //   line-height: 65px;
      //   letter-spacing: 0em;
      //   text-align: left;
      // }
      // h5 {
      //   font-size: 16px;
      //   font-weight: 400;
      //   line-height: 22px;
      //   letter-spacing: 0em;
      //   text-align: left;
      //   color: #828282 !important;
      // }
    }
    .policylists {
      ol {
        margin-left: 20px;
        li.parentList {
          line-height: 25px;
  
          h5 {
            font-size: 16px;
            font-weight: bold;
            color: black !important;
          }
          &::marker {
            font-size: 16px;
            font-weight: bold;
            color: black !important;
          }
        }
        li {
          font-size: 12px;
          color: #828282;
  
          &::marker {
            font-size: 12px;
            color: #828282;
          }
        }
      }
    }
  }
  