@import '../../general_style/var';

.singleConsultation {
  .sessions {
    width: 90%;
    margin: 0 auto;
    h2 {
      text-align: center;
      margin: 15px 0;
    }
  }
  .head_consultant {
    background-color: $bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 2rem 0;

    margin: auto;
    h3 {
      font-size: 20px;
      font-weight: 700;
      @media screen and (max-width: 540px) {
        font-size: 12px;
      }
    }
    img {
      @media screen and (max-width: 540px) {
        width: 25px;
      }
    }
    span {
      font-size: 20px;
      font-weight: 700;
      color: #998e95;
      @media screen and (max-width: 540px) {
        font-size: 14px;
      }
    }
  }
  .appointmentDetailsCard {
    box-shadow: 0 0 5px 5px #0000000a;
    min-height: 20px;
    width: 90%;
    margin: 25px auto;
    border-radius: 10px;
    padding: 20px;
    h2 {
      font-size: 19px;
      margin-top: 0;
      margin-bottom: 10px;
    }
    .detailsWrap {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .singleDetail {
        // flex-grow: 1;
        width: 33%;
        margin-bottom: 10px;

        .appointmentDetailsHead {
          font-weight: 700;
        }

        @media screen and (max-width: 760px) {
          width: 50%;
        }
      }
      .longSingleDetail {
        width: 33%;
        margin-bottom: 10px;

        .appointmentDetailsHead {
          font-weight: 700;
        }

        @media screen and (max-width: 760px) {
          width: 50%;
        }
        @media screen and (max-width: 540px) {
          width: 100%;
        }
      }
    }
  }
  .appointmentDetailsWrap {
    height: 100%;
    background-color: #f3f3f4;
    padding: 20px;
    border-radius: 40px;
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    .ant-divider {
      margin: 17px 0 !important;
    }
    .singleappointemtHeadDetail {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: bold;
      }
    }
  }
}
