@import '../../../assets/sass/mixins';
@import '../../../assets/sass/variables';
body .antd-custom-select {
  .ant-select {
    min-height: $inputHeight !important;

    .ant-select-selector {
      min-height: $inputHeight !important;
      font-family: var(--primaryFamily);
      width: 100%;
      outline-color: $primaryColor;
      background-color: rgba(153, 142, 149, 0.1) !important;
      font-size: $secondryFont !important;
      border-radius: $inputRadius !important;
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }
    .ant-select-selection-item {
      padding-left: 4px;
    }
    .ant-select-selection-placeholder {
      padding-left: 10px;
    }
  }
}
