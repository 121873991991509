@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Madimi+One:wght@400;700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
// background-color
$bg-color: #6a6fbf45;

//text-bg
$textBg: #000000;

//text-color
$text-color: #000000;

//green-bg
$greenTab-bg: #f5ffea;

// green-tab-color
$green-tab-text: #6a6fbf;
// font-family
$font-family: 'Titillium Web', sans-serif;
$Hind-Siliguri: 'Hind Siliguri', sans-serif;
$madimi-one: 'Madimi One', sans-serif;

//orange-color
$orange-color: #6a6fbf;
$greenColor: #6a6fbf;

$colors: (
  black-bg: rgba(9, 10, 14, 1),
  button-blue: rgba(106, 111, 191, 1),
  blue-gredient: linear-gradient(#2c81f9, #1b5aba),
  orange-gredient: linear-gradient(#fba759, #f37e44),
  simple-text-color: rgba(75, 85, 99, 1),
  white-color: rgba(255, 255, 255, 1),
  border-color: rgba(175, 184, 197, 1),
  border-color-03: rgba(175, 184, 197, 0.3),
  bg-color: rgba(243, 244, 246, 1),
  green-txt: rgba(33, 150, 83, 1),
  glass-opacity:
    linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)),
  button-orange: rgba(255, 146, 44, 1),
  button-orange-03: rgba(255, 146, 44, 0.3),
  orange-background: rgba(250, 255, 175, 1),
  sky-blue: rgba(86, 198, 207, 1),
  purple-color: rgba(51, 29, 44, 1),
  black-03: rgba(9, 10, 14, 0.35),
  border-color-05: rgba(175, 184, 197, 0.5)
);
