@import '../../general_style/var';
@import '../../assets/sass/mixins';
@import '../../assets/sass/variables';
.space {
  background-color: $bg-color;
  height: 200px;

  margin: auto;
}

.contact__wrapper {
  margin: auto;
  .contact {
    background-color: #ffffff;
    width: 60%;
    margin: auto;
    margin-top: -10rem !important;
    margin-bottom: 5.1rem !important;
    margin: auto;
    text-align: center;
    padding: 3rem 2.5rem;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    @media screen and (max-width: 1280px) {
      width: 55%;
      padding: 3rem 2rem;
    }
    @media screen and (max-width: 540px) {
      width: 95% !important;
      padding: 3rem 1rem;
    }
    h1 {
      padding-bottom: 1.5rem;
      font-family: $font-family;
      font-weight: 600;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      input {
        height: 45px;
        padding: 0 10px;
        background-color: #f8f7f8;
        border: none;
        border-radius: 10px;
        font-family: $primaryFamily !important;

        &:focus {
          outline: none;
        }
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          color: #998e95;
          font-family: $primaryFamily;
        }
      }
      textarea {
        padding: 10px;
        background-color: #f8f7f8;
        border: none;
        border-radius: 10px;
        max-width: 100%;
        font-family: $primaryFamily;

        &:focus {
          outline: none;
        }
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          color: #998e95;
          font-family: $primaryFamily;
        }
      }
      .error_name {
        color: red !important;
        text-align: left;
        font-size: 12px;
        margin-top: -10px;
        padding-left: 10px;
      }
    }
    .contact_btn {
      height: 45px;
      width: 38%;
      border-radius: 20px;
      border: none;
      margin-top: 3.5rem !important;
      margin: auto;
      background-color: $textBg;
      color: white;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      padding-bottom: 2px;
      @media screen and (max-width: 540px) {
        width: 50%;
      }
    }
  }
}

.modal_submitted {
  position: relative;
  .timer {
    position: absolute;
    width: 30px;
    right: 26px;
    top: 6px;
  }
}

// .ant-modal .ant-modal-close {
//   display: none;
// }

.ant-modal .ant-modal-content {
  max-width: 1000px !important;
  margin: auto !important;
}
.ant-form-item-explain-error {
  text-align: left;
}
