@import '../../../assets/sass/mixins';
@import '../../../assets/sass/variables';
.input-antd-custom {
  & > input {
    @include inputStyle;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus {
      border-color: $primaryColor;
      box-shadow: none;
    }
    &:hover {
      border-color: $primaryColor;
    }
    &::placeholder {
      color: #998e95;
      font-size: 12px;
      font-family: 'Titillium Web', sans-serif !important;
    }
  }
  .ant-input-affix-wrapper {
    @include inputStyle;
    // outline: $primaryColor !important;
    &:focus {
      border-color: transparent !important;
      box-shadow: none;
      outline: none !important;
    }
    &:hover {
      border-color: $primaryColor !important;
    }
    input {
      background-color: transparent;
      font-family: var(--primaryFamily);

      &::placeholder {
        color: #998e95;
        font-size: 12px;
      }
    }
  }
  .disabledAntInput {
    cursor: pointer !important;
    input {
      cursor: pointer !important;
    }
  }
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primaryColor !important;
  box-shadow: none;
  outline: $primaryColor !important;
}
