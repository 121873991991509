@import '../../general_style/var';

.psychJoinProviderWrapper {
  margin: 20px 0;
  display: flex;
  .joinProviderContentWrap {
    .heading {
      font-family: $madimi-one !important;
      font-size: 36px;
      font-weight: 400;
      line-height: 47.52px;
      text-align: left;
      margin-bottom: 10px;
    }
    .list {
      .item {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;
      }
    }
    .teritaryButton {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ff922c;
      color: #ff922c;
      font-family: $Hind-Siliguri;
      font-size: 17px;
      font-weight: 700;
      // line-height: 27.49px;
      text-align: center;
      height: 41px;
      padding: 0 30px;
      border-radius: 34px;
      background-color: #faffaf;
      cursor: pointer;
      width: fit-content;
      &:hover {
        background-color: #fff;
      }
    }
  }
}
