@import '../../general_style/var';

.psych-hero-section {
  width: 100%;
  .BannerTopWrapper {
    margin-top: 50px;
    display: flex;
    align-items: center;
    .bannerTextWrap {
      .gredient-text {
        color: map-get($colors, sky-blue);
        font-size: 20px;
        font-weight: 600;
        line-height: 32.34px;
        text-align: center;
        font-family: $Hind-Siliguri;
      }
      .heading-text {
        font-size: 34px;
        font-weight: 400;
        text-align: center;
        //   color: map-get($colors, black-bg);
        line-height: 47.52px;
        font-family: $madimi-one;
      }
    }
    .bannerImgWrap {
      .hero-image-container {
        svg {
          width: 100%;
        }
      }
    }
  }
}
