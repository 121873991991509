@import '../../general_style/var';

.search_category {
  height: 90%;

  margin: auto;
  h1 {
    font-size: 35px;
    font-weight: 600;
    color: $text-color;
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
    @media screen and (max-width: 540px) {
      font-size: 26px;
    }
  }
  .category_content {
    display: flex;
    flex-direction: column;
    padding: 0 3rem;
    justify-content: center;
    @media screen and (max-width: 768px) {
      padding: 0 1rem;
      flex-direction: column;
    }
    .img {
      width: 20%;
      text-align: center;
      @media screen and (max-width: 540px) {
        width: 45%;
        margin: auto;
      }
      img {
        width: 100%;
      }
    }
    .boxes {
      width: 80%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      // padding: 0 2rem;
      margin: auto;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }
      @media screen and (max-width: 540px) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        padding: 0;
      }
      .box {
        background: transparent;
        border: 1px solid rgba(153, 142, 149, 0.15);
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-radius: 30px;
        width: 100%;
        height: 50px;
        justify-content: space-between;
        cursor: pointer;
        .box_left {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 20px;
          }
          h4 {
            font-size: 15px;
            font-weight: 500;
            color: $text-color;
          }
        }
        img {
          width: 14px;
        }
      }
    }
  }

  .see-more {
    display: flex;
    justify-content: end;
    gap: 10px;
    cursor: pointer;
    width: 80%;
    margin: 1rem auto;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    img {
      width: 16px;
      margin-top: 2px;
    }
    p {
      font-size: 17px;
      font-weight: 600;
      color: $greenColor;
    }
  }

  // .cards {
  //   display: flex;
  //   gap: 3rem;
  //   width: 80%;
  //   margin-top: 3rem !important;
  //   margin: auto;

  //   @media screen and (max-width: 768px) {
  //     flex-direction: column;
  //     width: 70%;
  //   }
  //   @media screen and (max-width: 540px) {
  //     width: 90%;
  //   }
  //   .card {
  //     display: flex;
  //     width: 100%;
  //     margin: auto;
  //     border-radius: 30px !important;
  //     box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  //       rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  //     .left {
  //       width: 30%;
  //       text-align: center;
  //       border-top-left-radius: 30px;
  //       border-bottom-left-radius: 30px;
  //       @media screen and (max-width: 540px) {
  //         width: 25%;
  //       }
  //       img {
  //         width: 37px;
  //         margin-top: 3.5rem;
  //       }
  //     }
  //     .right {
  //       width: 75%;
  //       padding: 20px;
  //       @media screen and (max-width: 540px) {
  //         width: 100%;
  //         padding: 20px 10px;
  //       }
  //       h2 {
  //         font-size: 18px;
  //         font-weight: 600;
  //         padding-bottom: 5px;
  //       }
  //       p {
  //         font-size: 14px;
  //         font-weight: 400;
  //         color: #998e95;
  //         padding-bottom: 15px;
  //       }
  //       .buttons_category {
  //         display: flex;
  //         gap: 9px;
  //         button {
  //           background-color: $greenTab-bg;
  //           border: none;
  //           height: 31px;
  //           padding: 0 13px;
  //           border-radius: 20px;
  //           font-size: 10px;
  //           font-weight: 600;
  //           cursor: pointer;
  //           @media screen and (max-width: 540px) {
  //             padding: 0 17px;
  //             font-size: 10px;
  //           }
  //           @media screen and (max-width: 360px) {
  //             font-size: 8px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
