@import '../../general_style/var';

.consultationPageWrapper {
  margin: auto;
  .head {
    background-color: $bg-color;
    padding: 1rem 0 1rem 5.5rem;
    text-align: left;
    @media screen and (max-width: 540px) {
      padding: 1rem 0.5rem 1rem 1rem;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      color: $text-color;
      padding-bottom: 0.5rem;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #828282;
    }
  }
  .consultationsMainWrapper {
    width: 90%;
    margin: 0 auto;
    .consultationsHeading {
      margin: 15px 0;
    }
    .consultationsFiltersWrap {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      // padding-left: 5rem;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 540px) {
        padding-left: 1rem;
      }
      .filter_img {
        width: 32px;
        margin-bottom: 15px;
      }

      .ant-select {
        height: 35px;
        min-width: 250px;
      }
      .input-search {
        display: flex;

        input {
          min-width: 250px;
          height: 35px;
          padding-left: 10px;
          border: 1px solid #d9d9d9 !important;
          background: transparent;
          border-radius: 7px;
          outline: none;
        }
      }
    }
    .consultationsTableWrap {
      padding: 0 !important;
      @media screen and (max-width: 540px) {
        // overflow: scroll;
        padding: 0;
      }
      .ant-table-wrapper .ant-table-thead > tr > th {
        color: #998e95 !important;
      }
      .ant-table-wrapper .ant-table-pagination.ant-pagination {
        // display: none !important;
      }
      .ant-pagination-item-link {
        // background-color: red !important;
      }
      .ant-pagination .ant-pagination-next .ant-pagination-item-link {
        // background: transparent;
        box-shadow: 0px 0px 20.703702926635742px 0px #00000008;
      }
      .ant-pagination .ant-pagination-item-active {
        border: none !important;
        color: #000000;
      }
      .ant-pagination .ant-pagination-item-active a {
        color: #000000 !important;
        font-size: 17px !important;
      }
    }
  }

  .doctor_cards {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 2rem 0;
    width: 87%;
    margin: auto;
    gap: 20px;
    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 540px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      height: 165px;
      padding: 0rem 1rem;
      border-radius: 14px;
      box-shadow: 0px 0px 20.703702926635742px 0px #00000008;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      h5 {
        font-size: 14px;
        font-weight: 700;
        color: $text-color;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: #828282;
        margin-top: 6px;
      }
      button {
        border: none;
        height: 36px;
        padding: 0px 12px;
        border-radius: 20px;
        font-size: 14px;
        margin-top: 20px;
        color: $orange-color;
        font-weight: 700;
        background-color: #fff2ec;
        cursor: pointer;
      }
      .card_right {
        position: relative;
        img {
          width: 100%;
        }
        .imgStyle {
          width: 150px;
          position: absolute;
          top: -60px;
          right: -22px;
        }
        .imgStyle2 {
          width: 143px;
          position: absolute;
          top: -41px;
          right: -9px;
          overflow: hidden;
        }
      }
    }
  }

  .actionIcon {
    background-color: #6a6fbf;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    img {
      width: 20px;
    }
  }
  .ant-table {
    overflow-x: auto !important;
  }
}
