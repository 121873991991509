.doctorDetailsWrapper {
  margin: 30px auto;
  width: 90%;
  .doctorDetailHeading {
    width: 100%;
    text-align: center;
    font-size: 20px;
    // margin-bottom: 20px;
  }
  .ant-row {
    width: 100% !important;
  }
  .detailsWrapper {
    padding-right: 10px !important;

    .doctorBackButton {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        height: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    .headDetails {
      display: flex;
      // justify-content: center;
      // flex-direction: column;
      align-items: center;
      margin: 0 0 40px 0;
      gap: 20px;
      .profileImg {
        img,
        svg {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
      .doctorName {
        h3 {
          font-size: 20px;
          font-weight: bolder;
        }
        h4 {
          font-size: 13px;
          color: hsl(322, 5%, 58%);
          font-weight: 500;
        }
        .headTags {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
          flex-wrap: wrap;
          .singleTag {
            background-color: #f3f3f4;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px 10px;
            border-radius: 30px;
            color: #000 !important;
            svg {
              height: 18px;
              width: 18px;

              path {
                fill: #6a6fbf;
              }
            }
            img {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
    }
    .singleDetailWrapper {
      width: 100%;
      // margin-bottom: 30px;
      .singleDetailHead {
        font-size: 14px;
        font-weight: 700;
        margin: 10px 0;
      }
    }
    .siteMemberAchievements {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      .singleAchievement {
        width: 50%;
        display: flex;
        align-items: start;
        gap: 20px;
        margin-bottom: 15px;
        @media screen and (max-width: 580px) {
          width: 100%;
          margin-bottom: 15px;
        }
        svg {
          height: 25px;
          width: 25px;
          margin-top: 4px;
          path {
            fill: #6a6fbf;
          }
        }
        img {
          height: 25px;
          width: 25px;
          margin-top: 4px;
        }
        .content {
          h4 {
            font-size: 14px;
            margin-bottom: 4px;
          }
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
          }
        }
      }
      .singleAchievement.fullWidth {
        width: 100% !important;
      }
    }
  }
  .slotsWrapper {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 40px;
    height: fit-content;
    width: 100%;
    .noSlotWrapper {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
      }
    }
  }
  .memberDetailCard {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px 5px #0000000a;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    // min-height: 200px;
  }
}
.noProviderText {
  color: #998e95;
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
.clinicMembersWrapper {
  margin-top: 20px;

  .singleCard {
    .singleCardDetails {
      // min-height: 200px;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 5px 5px #0000000a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 15px;
      .detailsHead {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
        .image {
          height: 90px;
          width: 90px;
          border-radius: 50%;
          svg {
            height: 100%;
            width: 100%;
          }
          img {
            border-radius: 50%;
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .name {
        font-family: Titillium Web;
        font-size: 16px;
        font-weight: 700;
        // text-align: left;
        margin-bottom: 5px;
      }
      .providerDetailsWrap {
        display: flex;
        flex-direction: column;
      }
      .singleMemberProfileContent {
        display: flex;
        flex-wrap: wrap;
        .singleMemberProfileDetail {
          width: 50%;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;
          svg {
            width: 18px;
            height: 18px;
          }
          img {
            width: 18px;
          }

          .content {
            // margin-bottom: 7px;
            width: 100%;
            .contentHead {
              font-family: Titillium Web;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              margin-right: 10px;
            }
            .contentDetail {
              font-family: Titillium Web;
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              color: #998e95;
            }
          }
        }
      }
    }
  }
}
.singleDetailContent {
  .locationTab {
    background-color: #f3f3f4;
    padding: 10px;
    border-radius: 10px;
    p {
      color: black;
    }
  }
  .aboutDetailsWrap {
    align-items: center;
    display: flex;
    gap: 15px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 15px;
    }
    img {
      height: 30px;
    }
  }
}
.therapistsDetailHead {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}
.customSingleViewHeading {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 5px 0;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;

    margin-right: 7px;
  }
}
.customSingleViewParagraph {
  font-size: 12px;
  font-weight: 400;
  color: #998e95;
}
