@import '../../general_style/var';

.rate_experience {
  .therapistRating {
    font-size: 75px;
    color: #6a6fbf;
  }
  .ratings {
    padding: 1rem 0;
    width: 60%;
    margin: auto;
    @media screen and (max-width: 540px) {
      width: 95%;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
    img {
      @media screen and (max-width: 540px) {
        width: 100%;
      }
    }
    textarea {
      width: 100%;
      padding: 20px;
      border: none;
      outline: none;
      background-color: rgba(153, 142, 149, 0.07);
      border-radius: 10px;
      &::placeholder {
        font-size: 12px;
        font-weight: 400;
        color: #998e95;
      }
    }
    .review {
      font-size: 15px;
      font-weight: 700;
      color: white;
      height: 45px;
      display: flex;
      background-color: $text-color;
      align-items: center;
      margin-top: 2rem !important;
      margin-bottom: 4rem !important;
      margin: auto;
      width: 25%;
      justify-content: center;
      padding-bottom: 0.1rem;
      border-radius: 30px;
      cursor: pointer;
      @media screen and (max-width: 540px) {
        width: 40%;
      }
    }
  }
}
