@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Madimi+One&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

:root {
  --primaryFamily: 'Titillium Web', sans-serif;
  --secondaryFamily: 'Titillium Web', sans-serif;
}

$primaryColor: #000000;
$secondaryColor: #6a6fbf;
$thirdColor: #998e95;
$greenColor: #6a6fbf;
$inputRadius: 7px;
$inputHeight: 45px;
$inputPadding: 15px 20px 14px 20px;
$primaryFont: 14px;
$secondryFont: 12px;
$headingFont: 26px;
$fontBold: 700;
$fontRegular: 400;
$btnRadius: 34px;

$Hind-Siliguri: 'Hind Siliguri', sans-serif;
$primaryFamily: 'Titillium Web', sans-serif;
$madimi-one: 'Madimi One', sans-serif;

$desktopWidth: 1650px;

$alignPadding: 0px 30px;
$mediaQuerymobile: 580px;
