@import '../../../assets/sass/variables';
@import '../../../assets/sass/mixins';

.antd-custom-checkbox-2ndcolor {
  .ant-checkbox-wrapper {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $secondaryColor !important;
        border-color: $secondaryColor !important;
      }
    }
  }
}
