@import '../../general_style/var';

.account {
  margin: auto;
  // .ant-picker {
  //   background-color: #f8f7f8 !important;
  //   height: 40px !important;
  //   border: none !important;
  //   .ant-picker-input {
  //   }
  //   .ant-picker-suffix {
  //     color: $orange-color !important;
  //   }
  // }
  // .ant-picker-focused {
  //   box-shadow: none !important;
  // }

  // .ant-picker .ant-picker-input > input {
  //   font-size: 11px !important;
  //   font-weight: 600 !important;
  // }
  .headbg {
    background-color: $bg-color;
    height: 130px;
  }
  .head_account {
    display: flex;
    align-items: end;
    justify-content: center;
    height: 60px;
    gap: 10px;
    position: relative;
    width: fit-content;
    margin: auto;
    .edit_icon {
      position: absolute;
      right: 152px;
      bottom: -5px;
      background: white;
      width: 35px;
      padding: 8px;
      border-radius: 7px;
    }
    .accountname {
      padding-bottom: 10px;
      color: $text-color;
      text-transform: capitalize;
    }
  }
}

.account-upload-img {
  width: 136px;
  height: 136px;
  border-radius: 7px;
  background-color: #f8f6f4;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  label {
    position: absolute;
    width: 100%;
    height: 100%;
    // opacity: 0;
    cursor: pointer;
  }
  input {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
  }
  .updateImageLogo {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}
.accountForm {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin: auto;
  gap: 10px;
  padding: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  // .input_group {
  //   display: flex;
  //   flex-direction: column;
  //   position: relative;
  //   margin-bottom: 1rem;
  //   label {
  //     font-size: 13px;
  //     font-weight: 600;
  //     padding-bottom: 5px;
  //   }
  //   input {
  //     height: 45px;
  //     padding: 0 15px;
  //     border-radius: 5px;
  //     border: none;
  //     outline: none;
  //     background-color: #f8f7f8;
  //     &::placeholder {
  //       font-size: 11px;
  //       color: #998e95;
  //     }
  //   }

  //   // select {
  //   //   height: 45px;
  //   //   padding: 0 15px;
  //   //   border-radius: 5px;
  //   //   font-size: 11px;
  //   //   color: #998e95;
  //   //   border: none;
  //   //   outline: none;
  //   //   background-color: #f8f7f8;
  //   //   -webkit-appearance: none !important;
  //   // }
  //   .down {
  //     position: absolute;
  //     right: 13px;
  //     top: 40px;
  //     cursor: pointer;
  //   }
  // }
  // .date_of_birth {
  //   font-size: 13px;
  //   font-weight: 600;
  //   padding-bottom: 5px;
  // }

  // .input_group_date {
  //   display: flex;
  //   flex-direction: column;
  // }
  // .update_profile {
  //   height: 45px;
  //   width: 38%;
  //   border-radius: 30px;
  //   border: none;
  //   background-color: $textBg;
  //   color: white;
  //   margin: auto;
  //   font-weight: 700;
  //   margin-top: 2rem;
  //   margin-bottom: 4rem;
  //   cursor: pointer;
  //   @media screen and (max-width: 768px) {
  //     width: 45%;
  //   }
  // }
  // .error_name {
  //   color: red;
  //   font-size: 11px;
  //   padding-left: 10px;
  // }
}

// input {
//   font-size: 11px !important;
//   color: red !important;
// }
