@import '../../general_style/var';

.allProvidersWrapper {
  margin: 50px 0;
  h2 {
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    //   color: map-get($colors, black-bg);
    line-height: 47.52px;
    font-family: $madimi-one;
    margin-bottom: 30px;
  }
}
