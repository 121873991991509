@import '../../general_style/var';

.psychTopProvidersWrapper {
  .content-container {
    .title {
      font-size: 42px;
      font-weight: 400;
      line-height: 47.52px;
      font-family: $madimi-one;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      color: map-get($colors, simple-text-color);
      line-height: 21px;
      font-family: $Hind-Siliguri;
      margin-top: 15px;
    }
    .button-container {
      margin-top: 20px;
      p {
        font-family: $Hind-Siliguri;
        font-size: 17px;
        font-weight: 700;
        line-height: 27.49px;
        color: #ff922c;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }
  }
}
