@import '../../general_style/var';

.footer_dark {
  background-color: #fff;
  .psychCustomFooter {
    border-top: 3px solid rgba(175, 184, 197, 0.3);
    border-bottom: 3px solid rgba(175, 184, 197, 0.3);
  }

  .footer_center_dark {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    @media screen and (max-width: 540px) {
      text-align: center;
    }
    span {
      font-weight: 700;
      // color: #fff9c9;
    }
  }
  .footer_right_dark {
    font-weight: 700;
    color: #000;
    cursor: pointer;
  }
}

.footer_gray {
  background-color: #f5f4f4;
  .patientsFooterLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    font-family: $madimi-one !important;
    h3 {
      margin-bottom: 20px;
      color: black !important;
    }
    h4 {
      margin-bottom: 10px;
      cursor: pointer;
      font-weight: 400;
      color: black !important;

      a {
        // color: white;
        font-weight: 400;

        color: black !important;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.footer {
  // display: flex;
  align-items: center;
  color: #000;
  justify-content: space-between;
  // height: 135px;
  padding: 40px 0;
  .logo {
    // width: 20%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      cursor: pointer;
      height: 45px;
      width: auto;
    }
    // p {
    //   font-size: 24px;
    //   font-weight: 700;
    //   line-height: 36.5px;
    //   color: black;
    // }

    @media screen and (max-width: 768px) {
      img {
        width: 150px;
      }
    }
    @media screen and (max-width: 540px) {
      img {
        width: 100px !important;
      }
    }

    @media screen and (max-width: 350px) {
      img {
        width: 80px !important;
      }
    }
  }
  margin: auto;
  .bottomText {
    width: 100%;
    justify-content: center;
    font-family: $madimi-one;
    margin-top: 20px;
    p {
      margin-top: 20px;
      @media screen and (max-width: 540px) {
        font-size: 12px;
      }
    }
  }
  .ant-row {
    width: 100%;
    // padding: 0 30px;
  }
  .patientsFooterLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    font-family: $madimi-one !important;
    .customFooterText {
      font-family: $madimi-one !important;
      font-family: $Hind-Siliguri;
      font-size: 16px;
      font-weight: 100;
      line-height: 25.87px;
      color: #4b5563;
      margin-top: 20px;
    }
    h3 {
      margin-bottom: 20px;
      font-family: $madimi-one !important;
    }
    h4 {
      margin-bottom: 10px;
      cursor: pointer;
      font-weight: 100;
      font-family: $madimi-one !important;

      a {
        font-weight: 100;
        font-family: $madimi-one !important;

        color: #000;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
  // position: absolute;
  // width: 100%;
  // bottom: 0;
  @media screen and (max-width: 968px) {
    padding: 2rem 3rem;
    flex-direction: column;
    height: auto;
    gap: 1rem;
  }
  @media screen and (max-width: 540px) {
    padding: 2rem 1rem;
  }

  .footer_center {
    font-size: 14px;
    font-weight: 400;
    color: $text-color;
    @media screen and (max-width: 540px) {
      text-align: center;
    }
    span {
      font-weight: 700;
      // color: #fff9c9;
    }
  }
  .footer_right {
    font-weight: 700;
    color: $text-color;
    cursor: pointer;
  }
}
