// @import "../../assets//sass/variables";
@import '../../../assets/sass/mixins';
@import '../../../assets/sass/variables';

.footer-main-container {
  width: 100%;
  background-color: #f5f4f4;
  min-height: 135px;
  padding: 40px;
  .logo {
    width: 20%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      cursor: pointer;
      height: 45px;
      width: auto;
    }
    p {
      font-size: 24px !important;
      font-weight: 700;
      line-height: 36.5px;
      color: black;
    }

    @media screen and (max-width: 768px) {
      img {
        width: 150px;
      }
    }
    @media screen and (max-width: 540px) {
      img {
        width: 100px !important;
      }
    }

    @media screen and (max-width: 350px) {
      img {
        width: 80px !important;
      }
    }
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--primaryFamily);
    @media screen and (max-width: 580px) {
      flex-direction: column;
      row-gap: 20px;
    }
    p {
      font-size: $secondryFont;
      span {
        font-weight: $fontBold;
      }
    }
  }
}
