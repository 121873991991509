@import '../../assets/sass/mixins';
@import '../../assets/sass/variables';

.otp-screen-main {
  width: 100%;
  @include flex-center;

  height: calc(100vh - 135px);
  .otp-main-content {
    width: 50%;
    padding: 30px 0px;
    display: flex;
    height: 60%;
    align-items: center;
    flex-direction: column;
    .btn-center {
      margin-top: 20px;
    }
    @media screen and (max-width: 580px) {
      width: 90%;
    }
    .otp-header {
      display: flex;
      gap: 10px;
      align-items: center;
      svg {
        cursor: pointer;
      }
      h4 {
        @include headingStyle;
        font-size: 32px;
      }
    }
    & > p {
      @include paraStyleSecondryColor;
      margin-top: 5px;
    }
    .Otp-input-container {
      width: 100%;
      margin-top: 20px;
    }
    .btn-center-single {
      margin-top: auto;
    }
  }
}
