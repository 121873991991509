@import '../../../assets/sass//mixins';
@import '../../../assets//sass/variables';

.header-login {
  height: 70px;
  width: 100%;
  padding: $alignPadding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 20%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      cursor: pointer;
      height: 45px;
      width: auto;
    }
    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 36.5px;
      color: black;
    }

    @media screen and (max-width: 768px) {
      img {
        width: 150px;
      }
    }
    @media screen and (max-width: 540px) {
      img {
        width: 100px !important;
      }
    }

    @media screen and (max-width: 350px) {
      img {
        width: 80px !important;
      }
    }
  }
  .registerProviderButton {
    @media screen and (max-width: 580px) {
      color: #6a6fbf;
      background-color: transparent;
      width: auto;
      height: auto;
      padding: 3px 10px;
      font-weight: normal;
      font-size: 13px;
    }
  }
  svg {
    @media screen and (max-width: 580px) {
      height: 35px;
      width: auto;
    }
  }

  .joinTherapist {
    @include btnStyle;
    background-color: transparent;
    border: 1px solid $primaryColor;
    color: $primaryColor;
    font-size: 15px;
    &:hover {
      background-color: $primaryColor;
      color: white;
      transition: 0.1s ease-in;
    }
  }

  // @media screen and (max-width: 540px) {
  //   padding: 10px !important;
  //   svg {
  //     width: 150px;
  //     height: auto;
  //   }
  //   button.joinTherapist {
  //     width: 120px;
  //     height: 35px;
  //     padding: 0 !important;
  //   }
  // }
}
