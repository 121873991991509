@import '../../general_style/var';

.notification {
  min-height: 70vh;
  .head_notification {
    background-color: $bg-color;
    text-align: center;
    height: 70px;
    font-size: 26px;
    font-weight: 700;
    h2 {
      @media screen and (max-width: 540px) {
        font-size: 24px;
        padding-top: 1rem;
      }
    }
  }
  h4 {
    padding-left: 8rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    @media screen and (max-width: 768px) {
      padding-left: 2rem;
    }
  }
  .noti_content {
    background-color: #f8f7f8;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    @media screen and (max-width: 540px) {
      width: 95%;
    }
    .day_notification {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 0 0.7rem 0;
      width: 95%;
      margin: auto;
      border-bottom: 1px solid #e4e1e4;
      @media screen and (max-width: 540px) {
        flex-direction: column;
        gap: 10px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: $text-color;
      }
      button {
        background: transparent;
        border: none;
        color: $orange-color;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        @media screen and (max-width: 540px) {
          justify-content: end;
        }
      }
    }
  }
}

.notification_see_more {
  background: transparent;
  color: $orange-color;
  border: none;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: auto;
  cursor: pointer;
}
