@import '../../../assets/sass/mixins';
@import '../../../assets/sass/variables';
.antd-password-custom {
  .ant-input-affix-wrapper {
    @include inputStyle;
    input {
      background-color: transparent;
      &::placeholder {
        color: #998e95;
        font-size: 12px;
        font-family: 'Titillium Web', sans-serif !important;
      }
    }
  }
  .ant-input-affix-wrapper {
    @include inputStyle;
    outline: $primaryColor !important;
    box-shadow: none !important;
    border: 1px solid #998e95 !important;
    &:focus {
      border-color: $primaryColor !important;
      box-shadow: none !important;
      border: none !important;
    }
    &:hover {
      border-color: $primaryColor !important;
    }
    input {
      background-color: transparent;
      font-family: var(--primaryFamily);

      &::placeholder {
        color: #998e95;
        font-size: 12px;
      }
    }
  }
}
