@import '../../general_style/var';
.searchTherapistContainerWrapper {
  // padding-inline: 12%;
  width: 100%;
  margin-top: 10px;
  // @media screen and (max-width: 768px) {
  //   padding-inline: 10%;
  // }

  .searchTherapistWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    .searchContainer {
      margin-top: 25px;
      height: 74px;
      width: 922px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      //   box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      //     rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      //   background: map-get($colors, white-color);
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      @media screen and (max-width: 1024px) {
        width: 700px;
      }
      @media screen and (max-width: 992px) {
        width: 90%;
        margin-inline: auto;
        margin-left: 0;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      .markerSvg {
        margin-left: 20px;
        @media screen and (max-width: 600px) {
          margin-left: 10px;
        }
      }
      .searchField {
        height: 70px;
        border: none;
        width: 800px;
        text-indent: 20px;
        font-size: 16px;
        outline: none;
        background: map-get($colors, white-color);
        &:focus {
          background: transparent;
        }
        @media screen and (max-width: 1024px) {
          width: 550px;
          text-indent: 3px;
        }
        @media screen and (max-width: 820px) {
          width: 450px;
          text-indent: 10px;
        }
        @media screen and (max-width: 600px) {
          width: 200px;
          text-indent: 3px;
        }
      }

      .searchBtn {
        background-color: map-get($colors, button-blue);
        height: 46px;
        width: 46px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin: 15px;
        cursor: pointer;
        .searchBtnSvg {
          width: 20px;
          height: 20px;
        }
        @media screen and (max-width: 600px) {
          height: 40px;
          width: 40px;
          margin: 10px;
        }
      }
    }

    h2 {
      color: map-get($colors, white-color);
      font-size: 32px;
      font-weight: 400;
      font-family: $madimi-one;
      margin-top: 35px;
      padding-right: 70px;
      @media screen and (max-width: 768px) {
        padding-right: 10px;
        padding-left: 10px;
        font-size: 29px;
      }
    }
    .searchForm {
      display: flex;
      justify-content: center;
      background-color: #ffff;
      border-radius: 40px;
      width: 100% !important;
      margin: 0 auto !important;
      z-index: 2;
      position: relative;
      box-shadow: 0px 0.6666666865348816px 3.1481480598449707px 0px #00000003,
        0px 2.933333396911621px 6.518518447875977px 0px #00000005,
        0px 7.199999809265137px 13px 0px #00000006,
        0px 13.866666793823242px 25.481481552124023px 0px #00000008,
        0px 23.33333396911621px 46.85185241699219px 0px #0000000a,
        0px 36px 80px 0px #0000000d;

      @media screen and (max-width: 768px) {
        width: 70% !important;
      }
      @media screen and (max-width: 540px) {
        width: 100% !important;
        background-color: transparent;
        box-shadow: none;
      }
      .homeSearch {
        width: 100%;
        .simpleSearch {
          min-height: 45px;
          width: 100% !important;
          display: flex;
          padding: 0 8px;
          align-items: center;
          width: 100%;
          @media screen and (max-width: 540px) {
            flex-direction: column;
            padding: 0 12px 12px 12px;
            box-shadow: 0 0 5px 5px #0000000c;

            .category {
              position: absolute;
              left: 11px;
              top: 11px;
              z-index: 100;
            }
          }
          .addressFieldWrapper {
            .ant-form-item {
              .ant-form-item-control-input {
                .ant-input-affix-wrapper {
                  @media screen and (max-width: 540px) {
                    border-radius: 0 !important;
                    box-shadow: none !important;
                  }
                  .ant-input-suffix {
                    img {
                      width: 16px;
                    }
                  }
                }
              }
            }
          }
          .ant-input-suffix {
            @media screen and (max-width: 540px) {
              img {
                width: 15px !important;
              }
            }
          }
          .homeSearchButton {
            background-color: transparent;
            border: none;
          }
          @media screen and (max-width: 540px) {
            .homeSearchButton {
              background-color: #6a6fbf;
              height: 42px;
              width: 100%;
              border: none;
              cursor: pointer;
              img {
                background-color: transparent !important;
                box-shadow: none;
                padding: 0 !important;
                margin: 0 !important;
                width: 20px;
              }
            }
          }

          .addressFieldWrapper {
            width: 45%;
            @media screen and (max-width: 540px) {
              width: 100%;
            }
            .ant-form-item {
              width: 100%;
              margin: 0 !important;
              border: none !important;
              .ant-form-item-control-input-content {
                display: flex;
                padding-left: 10px;
                @media screen and (max-width: 540px) {
                  padding-left: 0;
                }
              }
              .ant-input-affix-wrapper {
                flex-direction: row-reverse !important;
                border: none;
                &:focus {
                  outline: none;
                  box-shadow: none !important;
                }
                &::placeholder {
                  color: #998e95;
                  font-weight: 400;
                  font-size: 16px;
                }
              }
            }
          }
          div.listWrapper {
            width: 45%;
            @media screen and (max-width: 540px) {
              width: 100%;
              margin-bottom: 0;
              position: relative;
              border-bottom: 1px solid #eeeeee;
            }
            .therapistInput {
              box-shadow: none !important;

              border-right: 1px solid black;
              border-left: none;
              border-bottom: none;
              border-top: none;
              border-radius: 0;
              @media screen and (max-width: 540px) {
                border-right: none;
                padding: 10px 38px;
                width: 100%;
                border-radius: 0;
                margin-bottom: 0 !important;
                box-shadow: none;
              }
              input {
                font-family: 'Titillium Web', sans-serif;

                &:focus {
                  box-shadow: none !important;
                }
                &::placeholder {
                  color: #998e95;
                  font-weight: 400;
                  font-size: 16px;
                  @media screen and (max-width: 540px) {
                    font-size: 13px;
                  }
                }
              }
            }
          }

          .ant-form-item {
            width: 45%;
            margin: 0 !important;
            .ant-input-affix-wrapper,
            .ant-select-selector {
              box-shadow: none !important;
              border: none !important;
              gap: 10px;
              padding: 0 !important;
              flex-direction: row-reverse !important;
              @media screen and (max-width: 540px) {
                padding: 10px 10px !important;
                border-radius: 22px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                  rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
              }
              input {
                font-family: 'Titillium Web', sans-serif;
              }
              .ant-select-selection-placeholder {
                color: #998e95;
                font-weight: 400;
                font-size: 16px;
              }
            }
            .ant-select-selection-search-input {
              font-family: 'Titillium Web', sans-serif;
            }
            .ant-form-item-control-input-content {
              display: flex;
              padding-left: 10px;
              @media screen and (max-width: 540px) {
                padding-left: 0;
              }
            }
            input,
            .ant-select-selection-search-input {
              border: none;
              &:focus {
                outline: none;
                box-shadow: none !important;
              }
              &::placeholder {
                color: #998e95;
                font-weight: 400;
                font-size: 16px;
                @media screen and (max-width: 540px) {
                  font-size: 13px;
                }
              }
            }
          }
          .arrow {
            margin-top: 6px;
            width: 30px;
            height: 30px;
            background-color: #6a6fbf;
            border-radius: 50%;
            padding: 10px;
            cursor: pointer;
            margin-left: 10px;
            @media screen and (max-width: 540px) {
              border-radius: 20px;
              width: 120px;
              height: 40px;
              margin-top: 10px;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            }
          }
        }
        .advanceSearch {
          width: 100%;
          padding: 25px;

          h3 {
            text-align: left;
          }
        }
      }
    }

    .secondaryCardWrapper {
      .secondaryCardWrap {
        background: map-get($colors, button-blue);
        width: 403px;
        height: 333px;
        border-radius: 30px;
        padding: 20px 40px;
        margin-top: 150px;
        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 20px 5px 0 5px;
          margin-top: 30px;
        }
        @media screen and (max-width: 992px) {
          width: 100%;
          padding: 20px 5px 0 5px;
          margin-top: 30px;
        }

        .imgsWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media screen and (max-width: 768px) {
            padding: 10px 20px;
            img {
              width: 100px;
            }
            .searchIcon {
              width: 60px;
            }
          }
          @media screen and (max-width: 992px) {
            padding: 10px 20px;
            img {
              width: 100px;
            }
            .searchIcon {
              width: 60px;
            }
          }
        }
        h3 {
          padding-top: 30px;
          font-size: 30px;
          font-weight: 400;
          font-family: $madimi-one;
          color: map-get($colors, white-color);
          @media screen and (max-width: 768px) {
            padding: 0 10px;
            font-size: 29px;
          }
          @media screen and (max-width: 992px) {
            padding: 0 20px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
