@import '../../../assets/sass/mixins';
@import '../../../assets/sass/variables';

.antd-custom-textarea {
  width: 100%;
  textarea {
    width: 100%;
    border-radius: 7px;
    &:hover {
      border-color: $primaryColor !important;
    }
  }
}
