.hiddenField {
  display: none !important;
}
.addressFieldWrapper {
  .locationsList {
    position: absolute;
    z-index: 2;
    background: white;
    .ant-list-items {
      .ant-list-item {
        cursor: pointer !important;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
