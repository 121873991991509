@import '../../general_style/var';

.search__therapist {
  margin: auto;
  button {
    width: auto !important;
    padding: 10px 15px;
    height: auto;
    font-size: 14px;
  }
  .search__therapist__bg {
    background-color: $bg-color;
    height: 300px;
    padding-top: 20px;
    @media screen and (max-width: 540px) {
      min-height: 255px;
      height: auto !important;
    }
    .search_hero__content {
      width: 100%;
      @media screen and (max-width: 540px) {
        margin-left: 0 !important;
        padding-top: 4%;
      }
      .searchTherapistForm {
        display: flex;
        justify-content: start;
        background-color: #ffff;
        border-radius: 40px;
        width: 50% !important;
        z-index: 2;
        margin-left: 32px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
          rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        @media screen and (max-width: 768px) {
          width: 80% !important;
        }
        @media screen and (max-width: 540px) {
          margin: 0 auto;
        }
        .homeSearchButton {
          background-color: transparent;
          border: none;
        }
        @media screen and (max-width: 540px) {
          .homeSearchButton {
            background-color: #6a6fbf !important;
            height: 42px !important;
            width: 100% !important;
            border: none !important;
            cursor: pointer !important;
            margin-top: 4px !important;
            img {
              background-color: transparent !important;
              box-shadow: none !important;
              padding: 0 !important;
              margin: 0 !important;
              width: 20px !important;
            }
          }
        }
        .homeSearch {
          width: 100%;
          .simpleSearch {
            display: flex;
            padding: 0;
            align-items: center;
            width: 100%;
            height: 32px;
            margin: 5px;
            position: relative;
            @media screen and (max-width: 540px) {
              flex-direction: column;
              margin: 0;
            }

            .category {
              @media screen and (max-width: 540px) {
                position: absolute;
                z-index: 2;
                left: 0px;
                top: 12px;
                width: 17px !important;
              }
            }
            .addressFieldWrapper {
              @media screen and (max-width: 540px) {
                margin-top: 5px;
              }
              .ant-input-suffix {
                img {
                  width: 20px;
                  @media screen and (max-width: 540px) {
                    width: 15px;
                  }
                }
              }
            }
            .listWrapper {
              width: 45%;
              @media screen and (max-width: 540px) {
                width: 100%;
                margin-bottom: 0;
                position: relative;
                line-height: 0 !important;
                border-bottom: 1px solid #eeeeee !important;
              }
              .therapistInput {
                border-right: 1px solid black;
                border-left: none;
                border-bottom: none;
                border-top: none;
                border-radius: 0;
                @media screen and (max-width: 540px) {
                  border-right: none;
                  padding: 0;
                }
                &:focus {
                  box-shadow: none !important;
                }
                input {
                  font-family: 'Titillium Web', sans-serif;
                  @media screen and (max-width: 540px) {
                    border-right: none;
                    padding: 10px 40px;

                    border-radius: 0;
                    margin-bottom: 0 !important;
                    box-shadow: none;
                  }
                  &:focus {
                    box-shadow: none !important;
                  }
                  &::placeholder {
                    color: #998e95;
                    font-weight: 400;
                    font-size: 16px;
                    @media screen and (max-width: 540px) {
                      font-size: 13px;
                    }
                  }
                }
              }
            }

            img {
              width: 30px;
            }
            button {
              background: transparent;
              border: none !important;
            }
            .addressFieldWrapper {
              width: 47%;
              @media screen and (max-width: 540px) {
                width: 100%;
              }
              .ant-form-item {
                width: 100%;
                margin: 0 !important;
                border: none !important;
                height: 32px !important;
                .ant-form-item-control-input-content {
                  display: flex;
                  padding-left: 10px;
                  @media screen and (max-width: 540px) {
                    padding: 0;
                    background-color: #ffff;
                    height: 42px;
                    border-radius: 0 !important;
                  }
                }
                .ant-input-affix-wrapper {
                  @media screen and (max-width: 540px) {
                    border-radius: 0 !important;
                    box-shadow: none !important;
                  }
                  flex-direction: row-reverse !important;
                  border: none;
                  &:focus {
                    outline: none;
                    box-shadow: none !important;
                  }
                  &::placeholder {
                    color: #998e95;
                    font-weight: 400;
                    font-size: 16px;
                  }
                  .ant-input-suffix {
                    @media screen and (max-width: 540px) {
                      margin-inline-start: 15px;
                    }
                  }
                }
              }
            }
            .ant-form-item {
              border-right: 1px solid black;
            }
            .ant-form-item {
              width: 47%;
              margin: 0 !important;
              height: 32px;
              .ant-row {
                height: 100%;
              }
              .input-antd-custom {
                width: 100%;
                input {
                  background-color: transparent !important;
                  padding: 0;
                }
              }
              .ant-input-affix-wrapper,
              .ant-select-selector {
                border: none !important;
                gap: 10px;
                padding: 0 !important;
                flex-direction: row-reverse !important;
                background-color: transparent !important;
                width: 100% !important;
                @media screen and (max-width: 540px) {
                  // padding: 10px 10px !important;
                  border-radius: 22px;
                  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
                }
                input {
                  font-family: 'Titillium Web', sans-serif;
                }
                .ant-select-selection-placeholder {
                  color: #998e95;
                  font-weight: 400;
                  font-size: 16px;
                }
              }

              @media screen and (max-width: 540px) {
                .ant-input {
                  background: transparent !important;
                }
              }

              .ant-select-selection-search-input {
                font-family: 'Titillium Web', sans-serif;
              }

              .ant-form-item-control-input-content {
                display: flex;
                padding-left: 10px;
              }
              input,
              .ant-select-selection-search-input {
                border: none;
                &:focus {
                  outline: none;
                  box-shadow: none !important;
                }
                &::placeholder {
                  color: #998e95;
                  font-weight: 400;
                  font-size: 16px;
                  @media screen and (max-width: 540px) {
                    font-size: 13px !important;
                  }
                }
              }
            }
            .arrow {
              margin-top: 6px;
              width: 32px;
              background-color: #000000;
              border-radius: 50%;
              padding: 10px;
              cursor: pointer;
              margin-left: 10px;
              @media screen and (max-width: 540px) {
                margin-top: 12px;
                width: 120px;
                height: 25px;
                border-radius: 20px;
                margin-left: 0;
              }
            }
          }
          .advanceSearch {
            width: 100%;
            padding: 25px;

            h3 {
              text-align: left;
            }
          }
        }
      }
      h2 {
        font-size: 32px;
        font-weight: 600;
        color: $text-color;
        line-height: 44px;
        padding-bottom: 14px;
        width: 55%;
        @media screen and (max-width: 768px) {
          font-size: 23px;
          line-height: 30px;
          padding-right: 0;
          width: 100%;
          padding-bottom: 3px;
        }
      }
      p {
        font-size: 12.5px;
        font-weight: 500;
        color: $text-color;
        margin-bottom: 1rem;
      }
    }
  }

  .ant-list {
    @media screen and (max-width: 540px) {
      z-index: 10 !important;
    }
  }

  .popular_tabs_search {
    display: flex;
    gap: 10px;
    margin-left: 32px;
    @media screen and (max-width: 540px) {
      margin-top: 70px;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0%;
      padding: 0 5px;
    }
    .highlighted_tab_search {
      background-color: rgba(245, 255, 234, 1);
      border: 1px solid #998e95;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 5px 7px;
      border-radius: 20px;
      gap: 7px;
      margin-top: 1rem;
      cursor: pointer;
      img {
        height: 18px;
        width: 18px;
      }
      p {
        font-size: 15px;
        color: black;
      }
      span {
        svg {
          height: 18px;
          width: 18px;
          path {
            fill: black;
          }
        }
      }
      .resetFilters {
        span {
          svg {
            height: 14px;
            width: 14px;
            margin-left: 8px;
          }
        }
      }
      p {
        @media screen and (max-width: 540px) {
          font-size: 11px;
        }
      }
      .ant-picker {
        background: transparent;
        border: none;
        padding: 0;
        .ant-picker-input {
          display: flex;
          flex-direction: row-reverse;
          .ant-picker-suffix {
            span {
              svg {
                height: 20px;
                width: 20px;
                color: black !important;
                margin-right: 10px;
              }
            }
          }
        }
        input {
          margin-top: 10px;
          &::placeholder {
            color: #000000;
            font-family: 'Titillium Web', sans-serif;
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
      @media screen and (max-width: 540px) {
        margin-top: 0;
      }
    }
  }

  .searchTimeSlots {
    display: flex;
    width: 100%;
    margin-top: -8rem !important;
    margin: auto;
    gap: 1rem;
    @media screen and (max-width: 540px) {
      margin-top: 0rem !important;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
    }
    #map {
      @media screen and (max-width: 1024px) {
        width: 90% !important;
        margin: 0 auto !important;
        border-radius: 40px;
      }
      @media screen and (max-width: 540px) {
        border-radius: 0px;
      }
    }
    .timeSlotsCards {
      margin-left: 2rem;
      width: 65%;
      @media screen and (max-width: 1024px) {
        width: 90%;
        margin: 0 auto;
      }

      h3 {
        margin-left: 9px;
        @media screen and (max-width: 540px) {
          display: none;
        }
      }
    }
    .card_slot {
      display: flex;
      background-color: white;
      width: 100%;
      padding: 20px;
      border-radius: 40px;
      box-shadow: 0 0 5px 5px #807c7c0d;
      margin-top: 1rem;
      margin-bottom: 2rem;
      gap: 2rem;
      @media screen and (max-width: 540px) {
        flex-direction: column;
        padding: 2rem 1rem;
      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 30%;
        @media screen and (max-width: 540px) {
          width: 100%;
        }
        .first__div {
          display: flex;
          align-items: center;
          gap: 20px;
          width: fit-content;
          // cursor: pointer;
          .name__stars .ant-rate {
            white-space: nowrap;
          }
          .imageContainer {
            height: 60px;
            width: 60px;
            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }
            svg {
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }
          }
        }
        .secound__div {
          display: flex;
          align-items: center;
          gap: 8px;
          .brainImg {
            width: 8%;
          }
          .secound__div__content {
            .para_heading {
              font-size: 12px;
              font-weight: 600;
              color: $text-color;
            }
            .para_title {
              font-size: 12px;
              font-weight: 400;
              color: #998e95;
            }
          }
        }
        .third__div {
          display: flex;
          align-items: center;
          gap: 8px;
          .location {
            width: 7%;
          }
          .para_heading {
            font-size: 12px;
            font-weight: 400;
            color: $text-color;
          }
          .para_title {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
          }
          img {
            width: 17px !important;
            height: auto !important;
          }
          .anticon {
            svg {
              height: 17px;
              width: 17px;
            }
          }
        }

        .Make_an_appointment {
          height: 35px;
          background-color: $textBg;
          padding: 0 10px;
          border: none;
          color: white;
          border-radius: 15px;
          font-weight: 700;
          margin-top: 1rem;
          width: 100%;
          cursor: pointer;
          @media screen and (max-width: 1280px) {
            width: 80%;
          }
        }
      }
      .right {
        width: 70%;
        display: flex;
        justify-content: space-between;
        position: relative;

        .ant-carousel {
          width: 90% !important;
          .slick-list {
            width: 100% !important;
            .time__slots {
              margin-right: 2px;
              margin-left: 2px;
            }
          }
        }
        @media screen and (max-width: 540px) {
          width: 100%;
        }
        // .right_top {
        //   display: flex;
        //   justify-content: space-between;
        //   .day_dates {
        //     text-align: center;
        //     h5 {
        //       font-size: 12px;
        //       text-align: center;
        //       font-weight: 700;
        //       color: #998e95;
        //     }
        //     p {
        //       font-size: 12px;
        //       text-align: center;
        //       font-weight: 400;
        //       color: #998e95;
        //     }
        //   }
        // }
        // .right_bottom {
        //   text-align: center;
        //   .box {
        //     background-color: #f5f4f4;
        //     height: 111px;
        //     margin-top: 2rem;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: center;
        //     border-radius: 20px;
        //     p {
        //       display: flex;
        //       align-items: center;
        //       gap: 10px;
        //       font-size: 13px;
        //       font-weight: 400;
        //       color: $text-color;
        //     }
        //     .Join_waitlist {
        //       height: 25px;
        //       margin-top: 10px;
        //       padding: 0 20px;
        //       border: none;
        //       color: white;
        //       background-color: $text-color;
        //       border-radius: 10px;
        //     }
        //   }
        // }

        .calenderLeft {
          position: absolute;
          left: 0;
          top: 12px;
          cursor: pointer;
        }
        .calenderRight {
          position: absolute;
          right: 0;
          top: 12px;
          cursor: pointer;
        }

        .right__one {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
            text-align: center;
            @media screen and (max-width: 540px) {
              font-size: 10px;
            }
          }
          .time__slots {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            p {
              padding: 5px 0;
              font-weight: 600;
              .greenSvgIcon {
                path {
                  fill: rgba(107, 166, 36, 1);
                }
              }
              @media screen and (max-width: 540px) {
                padding: 3px 0;
                font-weight: 600;
                .greenSvgIcon {
                  path {
                    fill: rgba(107, 166, 36, 1);
                  }
                }
              }
            }

            p.timeViewClinic {
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(245, 255, 234, 1);
              border-radius: 16px;
              color: rgba(107, 166, 36, 1);
              cursor: pointer !important;
              @media screen and (max-width: 540px) {
                svg {
                  margin: 0 !important;
                }
                span {
                  margin: 0 !important;
                }
              }
              .calenderIcon {
                height: 15px;
                width: auto;
                margin-right: 3px;
              }
            }
            p.timeViewHome {
              background: rgba(233, 122, 12, 0.1);
              border-radius: 16px;
              color: rgb(233, 122, 12);
              cursor: pointer !important;
            }
            p.timeViewVideo {
              background: rgba(245, 203, 17, 0.1);
              border-radius: 16px;
              color: rgb(245, 203, 17);
              cursor: pointer !important;
            }
          }
          .day {
            font-weight: 700;
            color: $text-color;
            font-size: 14px;
            @media screen and (max-width: 540px) {
              font-size: 12px;
            }
          }
          .dates__day {
            // display: flex;
            // justify-content: space-between;
          }
        }
        .right__two {
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
            text-align: center;
          }
          .time__slots {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            p {
              color: $text-color;
            }
          }
        }
        .right__three {
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
            text-align: center;
          }
          .time__slots {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            p {
              color: $text-color;
            }
          }
        }
        .right__four {
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
            text-align: center;
          }
          .time__slots {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            p {
              color: $text-color;
            }
          }
        }
        .right__five {
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
            text-align: center;
          }
          .time__slots {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            p {
              color: $text-color;
            }
          }
        }
        .right__six {
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
            text-align: center;
          }
          .time__slots {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            p {
              color: $text-color;
            }
          }
        }
        .right__seven {
          p {
            font-size: 12px;
            font-weight: 400;
            color: #998e95;
            text-align: center;
          }
          .time__slots {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            p {
              color: $text-color;
            }
          }
        }
      }
      .doctorsViewWrapper {
        width: 100%;
        border: 1px solid #c7c7c7;
        border-radius: 25px;
        max-height: 200px;
        overflow: scroll;
        .noProviderText {
          width: 100%;
          text-align: center;
          font-size: 17px;
          margin-top: 13px;
        }
        .singleDoctorCardDetailWrap {
          width: 100%;
          .singleDoctorItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            margin: 0 20px;
            border-bottom: 1px solid #c7c7c7;
            &:last-child {
              border: none !important;
            }
            .profileWrap {
              display: flex;
              align-items: center;
              gap: 10px;
              img {
                height: 50px;
                width: 50px;
                border-radius: 50%;
              }
              svg {
                height: 50px;
                width: 50px;
                border-radius: 50%;
              }
              .details {
                h5 {
                  text-transform: capitalize;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 19px;
                  letter-spacing: 0em;
                }
                p {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #828282;
                }
              }
            }
            .action {
              background-color: #6a6fbf;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 35px;
              width: 35px;
              border-radius: 50%;
              cursor: pointer;
              &:hover {
                opacity: 0.6;
                transition: 0.2s ease-in;
              }
            }
          }
        }
      }
    }
    #map {
      width: 60%;
      margin-top: 40px;
      text-align: center;
      border-radius: 40px;
      @media screen and (max-width: 540px) {
        width: 100% !important;
        margin-top: 0;
        margin-bottom: 3rem;
        border-radius: 0;
        height: 200px !important;
      }
      img {
        width: 100%;
      }
    }
    .mapboxgl-canvas {
      width: 100% !important;
    }
  }
}
.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem !important;
  margin-bottom: 3rem !important;
  width: fit-content;
  margin: auto;
  .butn {
    border: 1px solid $text-color;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 17px;
    cursor: pointer;
  }
}

.therapist-count {
  display: none;
  @media screen and (max-width: 540px) {
    display: block;
    text-align: center;
    padding: 20px 0 10px 0;
    font-size: 16px;
  }
}

.carousel_calender {
  .ant-carousel {
    // width: 396px !important;
    // @media screen and (max-width: 540px) {
    //   width: 290px !important;
    // }
  }
  .slick-list {
    width: 360px !important;
    @media screen and (max-width: 540px) {
      width: 285px !important;
    }
  }

  .ant-carousel .slick-prev::before {
    color: $orange-color !important;
    position: absolute !important;
    top: -70px !important;
  }
  .ant-carousel .slick-next::before {
    color: $orange-color !important;
    position: absolute;
    top: -70px !important;
  }

  .ant-carousel .slick-prev {
    background-color: red !important;
  }
}
.slick-slider {
  padding-left: 5px !important;
  @media screen and (max-width: 540px) {
    padding-left: 10px !important;
  }
  padding-left: 10px !important;
}
.ant-carousel .slick-prev::before {
  position: absolute;
  top: -70px !important;
  color: $orange-color;
}

.ant-carousel .slick-next::before {
  position: absolute;
  color: $orange-color;
  top: -70px !important;
}
.emptySchedules {
  background-color: #f5f3f4;
  width: 100%;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 540px) {
    padding: 30px !important;
  }
  span {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 6px;
      margin-left: 6px;
    }
  }
}
.formItemsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  width: 90%;
  margin: 0 auto;
  .waitlistElements {
    background: #000000;
    color: white !important;
    width: fit-content;
    padding: 5px;
    font-weight: bolder !important;
    border-radius: 7px;
    margin-bottom: 10px;
    font-size: 12px;
    span {
      margin-left: 3px;
      cursor: pointer;
      color: #6a6fbf;
      font-size: 12px;
    }
  }
}
.customAddWaitlistButton {
  width: 90%;
  display: flex;
  justify-content: end;
  margin: 0 auto;
  button {
    background: transparent;
    color: #6a6fbf;
    font-weight: bold !important;
    border: none;
    cursor: pointer;
  }
}
.timeFields {
  display: flex;
}
.customTherapistsSearchFilters {
  margin-top: 20px;
  min-width: 130px !important;
  &:focus {
    box-shadow: none !important;
  }
  .ant-select-selector {
    height: 38px !important;
    .ant-select-selection-search-input {
      height: 100% !important;
    }
    .ant-select-selection-placeholder {
      font-size: 20px !important;
      color: black !important;
    }
  }
  .ant-select-arrow {
    top: 35% !important;
  }
}
.customDropdownButtons {
  background-color: transparent !important;
  font-size: 14px !important;
  border: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  text-align: left !important;
  padding: 10px 1rem;
}
.customCaroselIcons {
  display: flex;
  align-items: start;
  height: fit-content;

  svg {
    color: $orange-color;
    margin-top: 10px;
  }
}
.ant-dropdown-menu-item-selected {
  background-color: transparent !important;
  .customDropdownButtons {
    color: #6a6fbf !important;
  }
}
.customNoBg {
  background-color: transparent;
  border: none;
  color: #6a6fbf;
  padding: 0 !important;
  font-weight: bold;
  font-size: 12px !important;
  cursor: pointer !important;
  &:hover {
    opacity: 0.7 !important;
  }
}
.daysSelectors {
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 30px;
  @media screen and (max-width: 580px) {
    column-gap: 5px;
    padding: 0 10px;
  }

  button.day {
    width: 100%;
    height: 30px;
    border-radius: 40px;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  button.activeDay {
    background-color: #6a6fbf;
    color: #ffffff;
  }
  button.disabledDay {
    opacity: 0.6;
    pointer-events: none !important;
  }
}
.singleSlotModal {
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 540px) {
    width: 100%;
  }
  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }
  .slotDetailWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    align-items: stretch; /* Stretch items vertically */

    .detailWrap {
      width: 47%;
      flex: 1;
    }
  }
  .makeAppointmentWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .detailWrap {
    border: 1px solid #f2f2f2;
    margin: 10px 0;
    padding: 15px 20px;
    border-radius: 40px;
    .doctorDetailsWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .doctorDetail {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          @media screen and (max-width: 540px) {
            height: 40px;
            width: 40px;
          }
        }
        svg {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          @media screen and (max-width: 540px) {
            height: 40px;
            width: 40px;
          }
        }
        .detailWrapInner {
          .name {
            font-size: 14px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
          }
          .appointmentType {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            color: #828282;
            text-transform: capitalize;
          }
        }
      }
      .feeDetail {
        font-family: Titillium Web;
        font-size: 24px;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
        color: #6a6fbf;
        padding-left: 20px;
        border-left: 1px solid #e0e0e0;
        @media screen and (max-width: 540px) {
          font-size: 18px;
        }
        span {
          font-family: Titillium Web;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          @media screen and (max-width: 540px) {
            font-size: 13px;
          }
        }
      }
    }

    .slotDetail {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h5 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 0;

        @media screen and (max-width: 540px) {
          font-size: 15px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        color: #828282;
      }
    }
    .frequencyWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .frequecyHead {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #828282;
      }
      .frequecyType {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: right;
      }
    }
  }
}
