@import '../../../assets/sass/variables';
@import '../../../assets/sass/mixins';
.antd-picker-custom {
  .ant-picker {
    outline: $primaryColor !important;
    box-shadow: none !important;
    border: 1px solid #998e95 !important;
    padding-right: 10px !important;

    @include inputStyle;
    .ant-picker-input input {
      color: #998e95;
      font-size: 12px;
      font-family: 'Titillium Web', sans-serif !important;
    }
  }
  .ant-picker-status-error {
    background-color: rgba(153, 142, 149, 0.1) !important;
  }
}
