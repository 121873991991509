@import '../../../assets//sass/variables';
@import '../../../assets//sass/mixins';

.primary-btn {
  @include btnStyle;
  &:disabled {
    background-color: $primaryColor;
    // color: white;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
