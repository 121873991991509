@import '../../general_style/var';

.about {
  margin: auto;
  .head_about {
    height: 150px;
    background-color: $bg-color;
    padding: 0 7rem;
    @media screen and (max-width: 768px) {
      padding: 0 2.4rem;
    }
    @media screen and (max-width: 768px) {
      height: 120px;
    }
    @media screen and (max-width: 540px) {
      height: 75px;
    }
    h1 {
      padding-top: 3.5rem;
      font-size: 48px;
      font-weight: 600;
      color: $text-color;
      @media screen and (max-width: 768px) {
        font-size: 35px;
      }
      @media screen and (max-width: 540px) {
        padding-top: 1rem;
        text-align: center;
      }
    }
  }
  .content {
    padding: 2rem 7rem;
    text-align: justify;
    @media screen and (max-width: 768px) {
      padding: 2rem 2.4rem;
    }
    @media screen and (max-width: 540px) {
      padding: 2rem 1rem;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      color: #998e95;
      @media screen and (max-width: 540px) {
        text-align: center;
      }
    }
  }
  .btn_div {
    width: fit-content;
    margin-top: 2rem !important;
    margin-bottom: 4rem !important;
    margin: auto;
  }
  button {
    display: flex;
    align-items: center;
    padding: 0 35px;
    gap: 1rem;
    height: 80px;
    border-radius: 40px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    color: $text-color;
    cursor: pointer;
  }
}
