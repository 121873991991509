@import './variables';

@mixin inputStyle {
  height: $inputHeight;
  padding: $inputPadding;
  width: 100%;
  border-radius: $inputRadius;
  font-size: $secondryFont;
  font-family: $primaryFamily;
  outline-color: $primaryColor;
  background-color: rgba(153, 142, 149, 0.1);
  &::placeholder {
    color: $thirdColor;
    font-family: var(--primaryFamily);
    font-size: $secondryFont;
  }
}

@mixin btnStyle {
  height: $inputHeight;
  font-family: var(--primaryFamily);
  font-weight: $fontBold;
  font-size: 15px;
  width: 195px;
  background-color: $primaryColor;
  color: white;
  border-radius: $btnRadius;
  border: 1px solid $primaryColor;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: $primaryColor;

    transition: 0.3s ease-in;
  }
}
@mixin btnGreenStyle {
  height: $inputHeight;
  font-family: var(--primaryFamily);
  font-weight: $fontBold;
  font-size: 15px;
  width: 195px;
  background-color: #6a6fbf;
  color: white;
  border-radius: $btnRadius;
  border: 1px solid #6a6fbf;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #6a6fbf !important;
    transition: 0.3s ease-in;
  }
  &:disabled {
    background-color: #6a6fbf;
    color: white !important;
  }
}
@mixin btnPurpleStyle {
  height: $inputHeight;
  font-family: 'Titillium Web', sans-serif;
  font-weight: $fontBold;
  font-size: 17px;
  min-width: 195px;
  background-color: #6a6fbf;
  color: white;
  border-radius: $btnRadius;
  border: 1px solid #6a6fbf;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #6a6fbf;

    transition: 0.3s ease-in;
  }
  &:disabled {
    background-color: #6a6fbf;
    color: white !important;
  }
}
@mixin paraStylePrimaryColor {
  font-family: var(--primaryFamily);
  font-size: $primaryFont;
  color: $primaryColor;
  font-weight: $fontRegular;
}
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin headingStyle {
  font-family: var(--primaryFamily);
  font-size: $headingFont;
  font-weight: 600;
  color: $primaryColor;
}
@mixin paraStyleSecondryColor {
  font-family: var(--primaryFamily);
  font-size: $secondryFont;
  color: $thirdColor;
  font-weight: $fontRegular;
}
