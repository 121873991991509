@import '../../general_style/var';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: auto;

  padding: 10px 25px;
  background-color: #090a0e;
  position: sticky;
  top: 0;
  @media screen and (max-width: 540px) {
    padding: 10px;
  }
  .mobileHidden {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }
  .logo {
    width: 20%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      cursor: pointer;
      height: 45px;
      width: auto;
    }
    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 36.5px;
      color: #fff;
    }

    @media screen and (max-width: 768px) {
      img {
        width: 150px;
      }
    }
    @media screen and (max-width: 540px) {
      img {
        width: 100px !important;
      }
    }

    @media screen and (max-width: 350px) {
      img {
        width: 80px !important;
      }
    }
  }
  .headerMenuWrapper {
    .profilePic {
      display: flex;
      align-items: center;
      gap: 10px;
      .headerUserProfileImage {
        height: 45px;
        width: 45px;
        border-radius: 50%;
      }
      .headerProfileDummyImg {
        svg {
          height: 45px;
          width: 45px;
          border-radius: 50%;
        }
      }
    }
    .headerMenu {
      display: flex;
      align-items: center;
      gap: 15px;
      .anticon {
        color: #fff;
        font-size: 30px;
        cursor: pointer;
        &:hover {
          color: #6a6fbf;
        }
      }
    }
  }
}
