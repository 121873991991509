@import '../../general_style/var';

// --------------common head ------------------//--

.head {
  background-color: $bg-color;
  text-align: center;

  margin: auto;
  h1 {
    font-size: 34px;
    font-weight: 700;
    color: $text-color;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    @media screen and (max-width: 540px) {
      font-size: 27px;
    }
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #828282;
  }
}

//--------------stepper----------------------//

// .ant-steps-item-icon {
//   background-color: $orange-color !important;
//   border: 1px solid $orange-color !important;
// }
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}

.parent__steps {
  margin: auto;
  .ant-steps {
    .ant-steps-item {
      pointer-events: none !important;
    }
  }

  .ant-steps {
    width: 50% !important;
    margin-top: 2.5rem !important;
    margin: auto;
    @media screen and (max-width: 768px) {
      width: 95% !important;
    }
    @media screen and (max-width: 540px) {
      .ant-steps-item {
        width: 30%;
        .ant-steps-item-content {
          .ant-steps-item-title {
            font-size: 12px;
          }
        }
      }
    }

    .ant-steps-icon {
      font-family: $font-family !important;
      font-weight: 700;
    }
    .ant-steps-item-icon {
      background-color: $textBg !important;
      border: 1px solid $textBg !important;
    }

    .ant-steps-item-tail {
      &::after {
        height: 2px !important;
        background: #998e95 !important;
      }
    }
    .ant-steps-item-wait .ant-steps-item-icon {
      background: transparent !important;
      border: 1px solid $text-color !important;
    }
  }
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: $orange-color !important;
  border: none !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #828282 !important;
}

.ant-steps-item .ant-steps-item-finish {
  background-color: $orange-color !important;
}

.make__appointment {
  margin-bottom: 4.7rem;
  position: relative;
  width: 50%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 85%;
  }
  .clinic {
    text-align: center;

    .clinic_head {
      font-size: 13px;
      color: $text-color;
    }
    .clinic_Address {
      font-size: 13px;
      font-style: italic;
      color: #998e95;
    }
  }
  .three__fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    .box_one {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: auto;
      padding: 0px 10px;
      height: 45px;
      border-radius: 10px;
      border: 1px solid #998e95;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .box_one_left {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          font-size: 15px;
          span {
            color: #998e95;
          }
        }
      }
    }
    .box_two {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: auto;
      padding: 0px 10px;
      height: 45px;
      border-radius: 10px;
      border: 1px solid #998e95;
      @media screen and (max-width: 768px) {
        width: 95%;
      }
      .box_one_left {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          font-size: 15px;
          span {
            color: #998e95;
          }
        }
      }
    }
    .box_three {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: auto;
      padding: 0px 10px;
      height: 45px;
      border-radius: 10px;
      border: 1px solid #998e95;
      @media screen and (max-width: 768px) {
        width: 95%;
      }
      .box_one_left {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          font-size: 15px;
          span {
            color: #998e95;
          }
        }
      }
    }
    .input_groups {
      width: 50%;
      margin: auto;
      position: relative;
      @media screen and (max-width: 768px) {
        width: 95%;
      }
      .location_stepper {
        position: absolute;
        right: 17px;
        top: 14px;
        cursor: pointer;
      }
      input {
        height: 45px;
        padding: 0 20px;
        width: 100%;
        border: none;
        background-color: #f8f7f8;
        border-radius: 10px;
        &::placeholder {
          color: #998e95;
          font-size: 12px;
          font-weight: 400;
        }
        &:focus {
          outline: none;
        }
      }
    }

    .stepper_btn {
      height: 45px;
      border-radius: 20px;
      border: none;
      margin-top: 4rem !important;
      margin: auto;
      background-color: $textBg;
      color: white;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        width: 40%;
      }
    }

    .ant-radio-group {
      font-size: 15px !important;
    }
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
      border-color: $orange-color !important;
      background-color: $orange-color !important;
    }
  }
  .customAppointmentAddressInput {
    .ant-input-affix-wrapper {
      flex-direction: row-reverse;
      gap: 10px;
      padding: 10px;

      .ant-input-suffix {
        img {
          height: 20px;
        }
      }
      input {
        font-family: 'Open Sans', sans-serif;

        &::placeholder {
          font-family: 'Open Sans', sans-serif;
        }
      }
    }
  }
}

.modal {
  text-align: center;
  padding: 1.5rem 5%;
  @media screen and (max-width: 540px) {
    padding: 1.5rem 0;
  }
  h1 {
    color: $text-color;
    @media screen and (max-width: 540px) {
      padding-top: 2rem;
    }
  }
  p {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-picker {
    width: 100% !important;
    height: 40px !important;
    background-color: #f5f4f4 !important;
    border: none !important;
    cursor: pointer;
  }

  .input_fields {
    text-align: left;
    margin: 2rem 0;
    .dates {
      width: 90%;
      margin: auto;
      @media screen and (max-width: 540px) {
        width: 100% !important;
      }
      h3 {
        font-size: 14px;
        font-weight: 700;
      }
      .ant-select {
        width: 100% !important;
        height: 45px !important;
        .ant-select-selector {
          height: 45px !important;
          padding: 6px 11px !important;
          background-color: #f5f4f4 !important;
        }
        .ant-select-arrow {
          color: $text-color !important;
        }
      }
    }
    .time {
      width: 64%;
      margin: auto;
      @media screen and (max-width: 540px) {
        width: 100% !important;
      }
      h3 {
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;
      }
      .ant-select {
        width: 100% !important;
        height: 45px !important;
        .ant-select-selector {
          height: 45px !important;
          padding: 6px 11px !important;
          background-color: #f5f4f4 !important;
          border: none !important;
        }
        .ant-select-arrow {
          color: $text-color !important;
        }
      }
    }
  }
  .modal_btn {
    height: 45px;
    width: 25%;
    border-radius: 20px;
    border: none;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin: auto;
    background-color: $textBg;
    color: white;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    @media screen and (max-width: 540px) {
      width: 45% !important;
    }
  }
}

.ant-modal {
  width: 66% !important;
  @media screen and (max-width: 540px) {
    width: 100% !important;
  }
}
.modal_submitted {
  padding: 1.5rem 10%;
  text-align: center;
  @media screen and (max-width: 540px) {
    padding: 1.5rem 0;
  }
  p {
    color: #998e95;
  }
  .modal_btn {
    height: 45px;
    width: 35%;
    border-radius: 20px;
    border: none;
    margin-top: 1.5rem !important;
    margin-bottom: 1rem !important;
    margin: auto;
    background-color: $textBg;
    color: white;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
}
.ant-modal-footer {
  display: none !important;
}
.ant-modal-close {
  color: #998e95 !important;
  font-weight: 800 !important;
  right: 2rem !important;
  top: 29px !important;
}
.ant-modal-content {
  border-radius: 50px !important;
}

// -----------------Time Slots---------- //

.timeSlots {
  display: flex;
  padding: 0 6rem;
  margin-top: 4rem !important;
  justify-content: center;
  margin: auto;
  @media screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
  }

  .month-year {
    width: 20%;
    padding-top: 1rem;
    @media screen and (max-width: 768px) {
      // width: 95%;
    }
    @media screen and (max-width: 768px) {
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
    h3 {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .ant-select-selector {
      height: 45px !important;
      padding: 6px 11px !important;
      background-color: #f5f4f4;
      .ant-select-selection-item {
        font-size: 10px !important;
      }
    }
    .ant-select {
      width: 95% !important;
      @media screen and (max-width: 768px) {
        width: 300px !important;
      }
      @media screen and (max-width: 540px) {
        width: 357px !important;
      }
      .ant-select-arrow {
        color: $text-color !important;
      }
    }
  }
  .table-slots {
    width: 75%;
    border: 5px solid #f8f6f4;
    border-radius: 20px;
    @media screen and (max-width: 768px) {
      width: 95%;
      margin: auto;
    }
    .head_timetable {
      width: 100%;
      display: flex;
      border-bottom: 5px solid #f8f6f4;
      .day {
        width: 20%;
        padding: 10px 0;
        h5 {
          font-size: 14px;
          font-weight: 700;
          color: #958070;
          padding-left: 2rem;
          padding-top: 3px;
        }
      }
      .available_time {
        width: 80%;
        padding: 10px 0;
        h5 {
          font-size: 14px;
          font-weight: 700;
          color: #958070;
          padding-left: 1rem;
          padding-top: 3px;
        }
      }
    }
    .timeTable_content_row_one {
      display: flex;
      margin-top: 1rem !important;
      border-bottom: 5px solid #f8f6f4;
      padding-bottom: 1rem;
      width: 95%;
      margin: auto;
      .left {
        width: 15%;
        border-right: 5px solid #f8f6f4;
        @media screen and (max-width: 540px) {
          width: 23%;
        }
        h3 {
          font-size: 16px;
          font-weight: 600;
          text-align: center;
        }
        p {
          font-weight: 700;
          text-align: center;
        }
      }
      .left.parentActive {
        color: $orange-color;
      }
      .right {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding-left: 2rem;
        .buttonTab {
          height: 31px;
          padding: 0 10px;
          border-radius: 10px;
          background-color: #f8f6f4;
          border: 1px solid #d4cfcc;
          cursor: pointer;
        }
        .buttonTab.active {
          background-color: $orange-color;
          color: white;
          border: 1px solid $orange-color;
        }
      }
    }
  }
}
.btn {
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  margin-right: 12%;
  margin-top: 0.7rem;
  color: $orange-color;
  cursor: pointer;
  @media screen and (max-width: 540px) {
    margin-right: 4%;
    margin-top: 0.4rem;
    font-size: 13px;
  }
}

.stepper_btn {
  height: 45px;
  border-radius: 20px;
  padding: 0 20px;
  border: none;
  margin-top: 1rem !important;
  margin: auto;
  background-color: $textBg;
  color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  // @media screen and (max-width: 540px) {
  //   width: 45%;
  //   padding-bottom: 2px;
  // }
}

.timeSlotsBtn {
  text-align: center;
  // margin-left: 9rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 540px) {
    margin-left: 0px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

// ---------------------Patient info-----------//
.patientInfo {
  width: 55%;

  margin: auto;
  @media screen and (max-width: 768px) {
    width: 85%;
    margin-top: 50px;
  }
  form {
    .input_groups {
      display: flex;
      flex-direction: column;
      margin-top: 0.6rem;
      position: relative;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      label {
        font-size: 13px;
        padding-left: 5px;
        padding-bottom: 4px;
      }
      input {
        height: 45px;
        padding: 0 20px;
        width: 100%;
        border: none;
        background-color: #f8f7f8;
        border-radius: 10px;
        &::placeholder {
          color: #998e95;
          font-size: 12px;
          font-weight: 400;
        }
        &:focus {
          outline: none;
        }
      }
      .custom {
        height: 45px;
        padding: 0 20px;
        width: 100%;
        border: none;
        background-color: #f8f7f8;
        border-radius: 10px;
        cursor: pointer;
        -webkit-appearance: none !important;
        &:focus {
          outline: none;
        }
      }
      .select_down {
        position: absolute;
        right: 18px;
        top: 39px;
        cursor: pointer;
      }
    }
  }

  .three__fields {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-top: 2rem;
    .relation {
      font-size: 16px;
    }
    .box_one {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: auto;
      padding: 0px 10px;
      height: 45px;
      border-radius: 10px;
      border: 1px solid #998e95;
      .box_one_left {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          font-size: 15px;
        }
      }
    }
    .box_two {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: auto;
      padding: 0px 10px;
      height: 45px;
      border-radius: 10px;
      border: 1px solid #998e95;
      .box_one_left {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          font-size: 15px;
        }
      }
    }
    .stepper_btn {
      height: 45px;
      width: 15%;
      border-radius: 20px;
      border: none;
      margin-top: 1rem !important;
      margin: auto;
      background-color: $textBg;
      color: white;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
    }

    .ant-radio-group {
      font-size: 15px !important;
    }
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
      border-color: $orange-color !important;
      background-color: $orange-color !important;
    }
  }

  .input_groups {
    display: flex;
    flex-direction: column;
    margin-top: 0.6rem;
    input {
      height: 45px;
      padding: 0 20px;
      width: 100%;
      border: none;
      background-color: #f8f7f8;
      border-radius: 10px;
      &::placeholder {
        color: #998e95;
        font-size: 12px;
        font-weight: 400;
      }
      &:focus {
        outline: none;
      }
    }
    .custom {
      height: 45px;
      padding: 0 20px;
      width: 100%;
      border: none;
      background-color: #f8f7f8;
      border-radius: 10px;
      -webkit-appearance: none !important;
      &:focus {
        outline: none;
      }
    }
    .select_down {
      position: absolute;
      right: 18px;
      top: 15px;
      cursor: pointer;
    }
  }
}
.timeSlotsWaitlist {
  display: flex;
  width: 90%;
  margin: 0 auto;
  gap: 20px;
  @media screen and (max-width: 540px) {
    width: 100%;
  }
  .time {
    width: 50%;
  }
}
.appointmentAddressField {
  position: relative;
  .locationsList {
    position: absolute;
    top: 50px;
  }
}
