@import './variables';
@import './mixins';
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: #fffefa;
  display: flex;
  align-items: center;
  justify-content: center;
}
html {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  display: none !important;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.ant-upload-wrapper .ant-input-affix-wrapper .ant-input {
  pointer-events: none;
}
#root {
  position: relative;
  min-height: 100vh;
  width: 100%;
  @media screen and (min-width: $desktopWidth) {
    width: $desktopWidth;
  }
  @media screen and (max-width: $desktopWidth) {
    width: 100%;
  }

  .backgroundColor {
    width: 100%;
    position: absolute;
    height: 70vh;
    background-color: #fff9c9;
    width: 100%;
    left: 0;
    z-index: -1;
  }
}

.ant-form-item-explain-error {
  font-size: $secondryFont;
  font-family: var(--primaryFamily);
}
.type-error {
  color: $secondaryColor;
  font-size: 9px;
  margin-top: -5px;
  font-family: var(--primaryFamily);
  margin-bottom: 3px;
}
.btn-center {
  @include flex-center;
}
.btn-center-single {
  @include flex-center;
}
.error_msg {
  color: red;
  font-size: 12px;
}
.ant-form-item-label {
  padding: 0 0 4px !important;

  label {
    font-family: var(--primaryFamily);
    font-size: $primaryFont;
  }
}
.ant-form-item-label > label.ant-form-item-required::before {
  content: none !important;
}
.ant-upload-list-item {
  display: none !important;
}
.uploaded-fileNames {
  display: flex;
  flex-wrap: wrap;
  .single-upload-file {
    padding: 5px;
    border-radius: 5px;
    width: fit-content !important;
    margin: 0 5px 10px 5px;
    background: #998e951a;
    cursor: pointer;
  }
}

.homeRoutes-main {
  display: flex;
  position: relative;
  & > div:last-child {
    width: calc(100% - 102px);
    @media screen and (max-width: 580px) {
      width: 100%;
      padding: 0 13px;
    }
  }
  .side-bar-main {
    max-width: 120px;
  }
}

.dashBoardTable_all {
  width: 100%;
  padding: $alignPadding;
  @media screen and (max-width: 580px) {
    padding: 0px;
  }

  .dashboard-Table-filters {
    margin: 30px 0px;
    width: 100%;
    display: flex;
    column-gap: 10px;
    .select-search-option {
      width: 30%;
      @media screen and (max-width: 580px) {
        width: 48%;
      }
      // .antd-custom-select {
      //   .ant-select-selector {
      //     background-color: transparent;
      //   }
      // }
    }
    .select-search-patient {
      width: 30%;
      @media screen and (max-width: 580px) {
        width: 48%;
      }
      .input-antd-custom {
        .ant-input-affix-wrapper {
          background-color: transparent;
        }
      }
    }
  }
}

.actions-in-table {
  display: flex;
  align-items: center;
  column-gap: 8px;
  svg {
    cursor: pointer;
  }
}

.appointment-table-type {
  display: flex;
  align-items: center;
  column-gap: 10px;
  p {
    @include paraStylePrimaryColor;
  }
}
.parentResentOTPButton {
  display: flex;
  justify-content: end;
  width: 100%;

  .resentOTPButton {
    color: $secondaryColor;
    font-size: 13px;
    font-family: var(--primaryFamily);
    font-weight: 700;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
.resentOTPButton {
  color: $secondaryColor;
  font-size: 13px;
  font-family: var(--primaryFamily);
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: fit-content;
}
.customAppointmentRadio {
  width: 100% !important;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.customAppointmentRadio::after {
  display: none;
}
.buttonDropDown {
  @include btnStyle;
  &:disabled {
    background-color: $primaryColor;
    color: white;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: $secondaryColor !important;
  color: white !important;
}
.disabledButton {
  opacity: 0.6;
  pointer-events: none !important;
}
.whiteSvgIcon {
  path {
    fill: white;
  }
}
.swal2-container {
  font-family: var(--primaryFamily) !important;
  .swal2-icon-show {
    border: none !important;
  }
  .swal2-popup {
    border-radius: 20px;
  }
  .swal2-title {
    font-size: 1em !important;
  }

  .swal2-actions {
    flex-direction: row-reverse;
    .swal2-confirm {
      @include btnGreenStyle;
      background-color: #6a6fbf !important;
      &:hover {
        background-color: white !important;
        background-image: none !important;
      }
    }
    .swal2-cancel {
      background-color: transparent !important;
      background-image: none !important;
      color: $secondaryColor !important;
      font-size: 14px;
      font-family: var(--primaryFamily) !important;
      padding: 0 !important;
    }
  }
}
.ant-picker-dropdown {
  // .ant-picker-footer {
  //   display: none;
  // }
}
.cards {
  display: flex;
  gap: 20px;
  width: 80%;
  margin-top: 3rem !important;
  margin: auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 70%;
  }
  @media screen and (max-width: 540px) {
    width: 90%;
  }
  .card {
    display: flex;
    width: 100%;
    margin: auto;
    border-radius: 30px !important;
    box-shadow: 0 0 5px 5px #f6f6f6;
    .left {
      width: 30%;
      text-align: center;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 540px) {
        width: 25%;
      }
    }
    .right {
      width: 75%;
      padding: 20px;
      position: relative;
      @media screen and (max-width: 540px) {
        width: 100%;
        padding: 20px 10px;
      }
      h2 {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin: 0;
        line-height: 100%;
        &:last-child {
          margin-bottom: 10px;
        }
      }
      h2.subHead {
        margin-bottom: 15px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #998e95;
        padding-bottom: 15px;
      }
      .buttons_category {
        display: flex;
        gap: 9px;
        button {
          position: absolute;
          bottom: 24px;
          background-color: #000000;
          border: none;
          height: 31px;
          padding: 0 13px;
          border-radius: 20px;
          font-size: 10px;
          font-weight: 600;
          cursor: pointer;
          @media screen and (max-width: 980px) {
            padding: 0 10px !important;
            font-size: 10px !important;
            position: inherit !important;
          }
          @media screen and (max-width: 540px) {
            padding: 0 17px;
            font-size: 10px;
            position: absolute !important;
          }
          @media screen and (max-width: 360px) {
            font-size: 8px;
          }
        }
      }
    }
  }
}
.customSearchList {
  position: absolute;
  background: white;
  top: 40px;
  min-width: 98%;
  max-height: 300px;
  overflow-x: scroll;
  left: 10px;
  padding: 0 !important;
  z-index: 1;
  @media screen and (max-width: 640px) {
    min-width: 100% !important;
  }
  .ant-list-items {
    li.ant-list-item {
      cursor: pointer;

      p {
        margin: 0 !important;
        font-size: 14px;
        @media screen and (max-width: 640px) {
          font-size: 12px;
        }
      }
      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        object-fit: contain !important;
        @media screen and (max-width: 640px) {
          height: 20px;
          width: 20px;
        }
      }
      .listNames {
        display: flex;
        gap: 10px;
        align-items: center;
        @media screen and (max-width: 640px) {
          p {
            padding: 0 !important;
          }
        }
      }
    }
  }
  .customSearchListItemHead {
    background-color: #e2e2e2;
    pointer-events: none !important;
    cursor: alias !important;
    p {
      font-weight: bold !important;
    }
  }
  .customSearchListItem {
    &:hover {
      background-color: #f3f3f3;
    }
    p {
      text-transform: capitalize;
    }
  }
}
.customNoHoverDropDownElement {
  .ant-dropdown-menu-item {
    cursor: default !important;
    &:hover {
      background-color: transparent !important;
      background: transparent !important;
    }
  }
}
.ant-input-outlined:focus,
.ant-input-outlined:active,
.ant-input-affix-wrapper {
  outline: none !important;
  box-shadow: none !important;
}
.doctorInfoHead {
  width: 70%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  @media screen and (max-width: 540px) {
    width: 100% !important;
    margin: 1.5rem !important;
  }
}
.slotSingleDetailWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.largePurpleButton {
  height: 55px !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  width: 395px !important;
}
.psychContainer {
  width: 90%;
  margin-inline: auto;
}
// .ant-select-single {
//   .ant-select-arrow {
//     margin-top: 0 !important;
//   }
// }
