@import '../../../assets//sass/variables';
@import '../../../assets//sass/mixins';

.primary-btn-purple {
  @include btnPurpleStyle;
  &:disabled {
    color: #6a6fbf;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
