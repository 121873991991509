@import '../../assets/sass//mixins';
@import '../../assets//sass/variables';
.signup-main-container {
  width: 100%;
  @include flex-center;
  .loginImageSide {
    position: absolute;
    top: 0;
    left: 0;
    svg {
      color: red;
    }
  }

  .customModalDisclaimer {
    width: 100% !important;
  }
  .login-main-content {
    width: 50%;
    padding: 30px 0px;
    @media screen and (max-width: 580px) {
      width: 90%;
    }
    .login-button {
      margin-top: 20px;
    }
    h3 {
      font-size: 32px !important;
      font-weight: 600 !important;
      text-align: center;
      @include headingStyle;
      font-weight: $fontBold;
    }
    .forget-password {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: -20px;
      p {
        color: $secondaryColor;
        font-weight: $fontBold;
        font-family: var(--primaryFamily);
        cursor: pointer;
        font-size: $primaryFont;
      }
    }
    .btn-center {
      .primary-btn {
        width: 241px;
      }
    }
  }
}

.header-login {
  height: 70px;
  width: 100%;
  padding: $alignPadding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .joinTherapist {
    @include btnStyle;
    background-color: transparent;
    border: 1px solid $primaryColor;
    color: $primaryColor;
    font-size: 15px;
    &:hover {
      background-color: $primaryColor;
      color: white;
      transition: 0.1s ease-in;
    }
  }
}

.otp-screen-main {
  width: 100%;
  @include flex-center;

  height: calc(100vh - 135px);
  .otp-main-content {
    width: 50%;
    padding: 30px 0px;
    display: flex;
    height: 60%;
    align-items: center;
    flex-direction: column;
    .btn-center {
      margin-top: 20px;
    }
    @media screen and (max-width: 580px) {
      width: 90%;
    }
    .otp-header {
      display: flex;
      gap: 10px;
      align-items: center;
      svg {
        cursor: pointer;
      }
      h4 {
        @include headingStyle;
        font-size: 32px;
      }
    }
    & > p {
      @include paraStyleSecondryColor;
      margin-top: 5px;
    }
    .Otp-input-container {
      width: 100%;
      margin-top: 20px;
    }
    .btn-center-single {
      margin-top: auto;
    }
  }
}
