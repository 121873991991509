@import '../../general_style/var';

.doctorCalenderWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;

  .ant-carousel {
    width: 90% !important;
    .slick-list {
      width: 100% !important;
      .time__slots {
        margin-right: 2px;
        margin-left: 2px;
      }
    }
  }
  @media screen and (max-width: 540px) {
    width: 100%;
  }

  .calenderLeft {
    position: absolute;
    left: 0;
    top: 12px;
    cursor: pointer;
  }
  .calenderRight {
    position: absolute;
    right: 0;
    top: 12px;
    cursor: pointer;
  }

  .right__one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #998e95;
      text-align: center;
      @media screen and (max-width: 540px) {
        font-size: 10px;
      }
    }
    .time__slots {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      p {
        padding: 5px 0;
        font-weight: 600;
        .greenSvgIcon {
          path {
            fill: rgba(107, 166, 36, 1);
          }
        }
        @media screen and (max-width: 540px) {
          padding: 3px 0;
          font-weight: 600;
          .greenSvgIcon {
            path {
              fill: rgba(107, 166, 36, 1);
            }
          }
        }
      }

      .timeSlotViewWrapper {
        background: rgba(245, 255, 234, 1);
        color: rgba(107, 166, 36, 1);
        min-height: 50px;
        display: flex;
        border-radius: 16px;

        justify-content: center;
        align-items: center;
        p.timeViewClinic {
          cursor: pointer !important;

          width: 100%;
          .calenderIcon {
            height: 15px;
            width: auto;
            margin-right: 3px;
          }
          @media screen and (max-width: 540px) {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            flex-direction: column !important;
            gap: 3px;
            svg {
              margin: 0 !important;
            }

            span {
              margin: 0 !important;
            }
          }
        }
      }
      .timeSlotViewWrapper.nobg {
        background-color: transparent !important;
      }
      p.timeViewHome {
        background: rgba(233, 122, 12, 0.1);
        border-radius: 16px;
        color: rgb(233, 122, 12);
        cursor: pointer !important;
      }
      p.timeViewVideo {
        background: rgba(245, 203, 17, 0.1);
        border-radius: 16px;
        color: rgb(245, 203, 17);
        cursor: pointer !important;
      }
    }
    .day {
      font-weight: 700;
      color: $text-color;
      font-size: 14px;
      @media screen and (max-width: 540px) {
        font-size: 12px;
      }
    }
    .dates__day {
      // display: flex;
      // justify-content: space-between;
    }
  }
  .right__two {
    p {
      font-size: 12px;
      font-weight: 400;
      color: #998e95;
      text-align: center;
    }
    .time__slots {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      p {
        color: $text-color;
      }
    }
  }
  .right__three {
    p {
      font-size: 12px;
      font-weight: 400;
      color: #998e95;
      text-align: center;
    }
    .time__slots {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      p {
        color: $text-color;
      }
    }
  }
  .right__four {
    p {
      font-size: 12px;
      font-weight: 400;
      color: #998e95;
      text-align: center;
    }
    .time__slots {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      p {
        color: $text-color;
      }
    }
  }
  .right__five {
    p {
      font-size: 12px;
      font-weight: 400;
      color: #998e95;
      text-align: center;
    }
    .time__slots {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      p {
        color: $text-color;
      }
    }
  }
  .right__six {
    p {
      font-size: 12px;
      font-weight: 400;
      color: #998e95;
      text-align: center;
    }
    .time__slots {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      p {
        color: $text-color;
      }
    }
  }
  .right__seven {
    p {
      font-size: 12px;
      font-weight: 400;
      color: #998e95;
      text-align: center;
    }
    .time__slots {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      p {
        color: $text-color;
      }
    }
  }
}
