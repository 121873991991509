@import '../../general_style/var';

.psychTestimonialsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  .main-container {
    width: 90%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 400px;
    .review-cover {
      right: 130px;
      position: absolute;
    }
    .button-container {
      top: 60px;
      right: 138px;
      z-index: 1;
      position: absolute;
      .testimonial-buttons {
        margin: 10px;
        border: none;
        cursor: pointer;
        background-color: transparent;
      }
    }
    .testimonial-slider {
      width: 90%;
      margin-top: 30px;
      .testimonial-card {
        padding: 30px;
        display: flex;
        justify-content: start;
        gap: 56px;
        .testimonial-card-image-section {
          display: flex;
          justify-content: space-between;
          gap: 50px;
          .testimonial-user {
            .testimonial-user-img {
              width: 151px;
              height: 151px;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
            }
            .testimonial-txt {
              color: map-get($colors, simple-text-color);
              font-size: 14px;
              font-weight: 400;
              font-family: $madimi-one;
              line-height: 21px;
              margin-top: 3px;
              text-align: center;
            }
          }
        }
        .testimonial-content-section {
          display: flex;
          flex-direction: column;
          .crd-title {
            font-size: 32px;
            font-weight: 400;
            line-height: 47.52px;
            font-family: $madimi-one;
            color: map-get($colors, black-bg);
            .crd-spn {
              color: map-get($colors, button-blue);
              font-family: $madimi-one;
            }
          }
          .testimonial-text {
            width: 499px;
            font-size: 14px;
            line-height: 21px;

            font-weight: 400;
            font-family: $Hind-Siliguri;
            color: map-get($colors, simple-text-color);
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .psychTestimonialsWrapper {
    .main-container {
      .review-cover {
        right: 0px;
      }
      .testimonial-slider {
        .testimonial-card {
          .testimonial-card-image-section {
            .testimonial-user {
              .testimonial-user-img {
                width: 125px;
                height: 125px;
              }
            }
          }
          .testimonial-content-section {
            .testimonial-text {
              width: 500px;
            }
            .testimonial-text2 {
              width: 500px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .psychTestimonialsWrapper {
    .main-container {
      .button-container {
        right: 100px;
      }
      .testimonial-slider {
        .testimonial-card {
          .testimonial-card-image-section {
            gap: 10px;
            .testimonial-user {
              .testimonial-user-img {
                width: 120px;
                height: 120px;
              }
            }
          }
          .testimonial-content-section {
            .testimonial-text {
              width: 370px;
            }
            .testimonial-text2 {
              width: 370px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .psychTestimonialsWrapper {
    .main-container {
      .button-container {
        top: 25px;
        right: 120px;
      }

      .testimonial-slider {
        .testimonial-card {
          .testimonial-card-image-section {
            gap: 10px;
            .testimonial-user {
              .testimonial-user-img {
                width: 110px;
                height: 120px;
              }
            }
          }
          .testimonial-content-section {
            .testimonial-text {
              width: 370px;
            }
            .testimonial-text2 {
              width: 370px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .psychTestimonialsWrapper {
    margin-top: 20px;
    .testimonial-title-container {
      .testimonial-title {
        text-align: center;
      }
    }
    .main-container {
      width: 95%;
      height: auto;
      padding: 30px 0px;
      .review-cover {
        right: 0;
        bottom: 0;
        height: 250px;
      }
      .button-container {
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        gap: 10px;
        margin-inline: auto;
        .testimonial-buttons {
          margin: 0px;
        }
      }
      .testimonial-slider {
        width: 80%;
        .testimonial-card {
          padding: 15px;
          flex-direction: column;
          gap: 0px;
          .testimonial-card-image-section {
            .testimonial-user {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              .testimonial-user-img {
                width: 130px;
                height: 130px;
              }
            }
          }
          .testimonial-content-section {
            width: 100%;
            .crd-title {
              text-align: center;
            }
            .testimonial-text {
              width: 100%;
              font-size: 14px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
